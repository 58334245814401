import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  justify-content: flex-start;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const FunctionFilter = ({ filterText3, handleKeyDown, onFilter3, onClear }) => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return(
    <>
      <Input
        type="text"
        placeholder={t("Filter by function")}
        value={filterText3}
        onChange={onFilter3}
        onKeyDown={handleKeyDown}
        id="funcfilter"
      />
      <ClearButton onClick = {onClear}>X</ClearButton>
    </>
  );
  
};

export default FunctionFilter;


