
import React, { useState, Fragment } from "react";
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import AuthcustService from "./authcust.service";
import AlertService from "../../../components/Alerts/alerts";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";



const CustomerLogin = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregister() {
    localStorage.setItem("register", true)
  }

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = "Email Required.";
    }
      else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!values.password) {
      errors.password = "Password Required.";
    }
    return errors;
  };

  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    AuthcustService.login(values.email, values.password).then((response) => {
      console.log("Login:-", response);
      setLoading(false);
      // AlertService.successToast("Login Successful.")
      navigate("/customer/warranties-list", {state:response});
      window.location.reload();
    },
    (error) => {
      setLoading(false);
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if(resMessage === 'Unauthorized') {
          AlertService.swalErrorAlert("Invalid Credentials.")
        }
        else {
          AlertService.swalErrorAlert(resMessage)
        }
      }
    );
  };

    return (
        <Fragment>
            <Form onSubmit={onSubmit}
                validate={validateFtn}
                render={({ handleSubmit, values, submitting, validating, valid }) => (
                  <form onSubmit={handleSubmit}> 
                    <div className="modal-dialog w-100 mx-auto shadow-sm">
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>
                        <div className="modal-content border-light">
                          <div className="modal-body p-5">
                            <div className="h5 modal-title text-center">
                            {/* <div className="app-logo mx-auto mb-3" /> */}
                            <img src={logo} className="app-logo mx-auto mb-3" />
                              <h6 className="mb-5">                                
                                <span>Sign in to your account below.</span>
                              </h6>
                            </div>
                              <Row form>
                                <Col md={12}>
                                  <FormGroup>
                                    <Field name="email">
                                      {({ input, meta }) => (
                                        <div className="mb-3">
                                          <Label for="email">Email</Label>
                                          <Input {...input} type="email" 
                                            invalid={meta.error && meta.touched} 
                                            placeholder="Enter Email"
                                          />
                                          {meta.error && meta.touched && 
                                          <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup>
                                    <Field name="password">
                                      {({ input, meta }) => (
                                        <div className="mb-3">
                                          <Label for="password">Password</Label>
                                          <Input {...input} type="password"
                                            invalid={meta.error && meta.touched}
                                            placeholder="**********"
                                          />
                                          {meta.error && meta.touched && 
                                          <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <FormGroup check  className="mb-3">
                                {/* <Input type="checkbox" name="check" id="exampleCheck" />
                                <Label for="exampleCheck" check>
                                  Keep me logged in
                                </Label> */}
                              </FormGroup>
                              <FormGroup>
                                <div>
                                  <div className="d-grid mb-3">
                                    <Button className="btn-custom-color" size="lg" type="submit" disabled={!valid}>
                                      Login
                                    </Button>
                                  </div>
                                  <div className="text-center" >
                                    <a href="/customer/register" className="btn-lg btn btn-link" onClick={handleregister}>
                                      Register
                                    </a>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    <a href="/customer/forgot-password" className="btn-lg btn btn-link" onClick={handlereset}>
                                      Forgot password?
                                    </a>
                                  </div>
                                </div>
                              </FormGroup>
                          </div>
                        </div>
                      </LoadingOverlay>
                    </div>
                  </form>
                )}
              />

        </Fragment>
    );

}
export default CustomerLogin;