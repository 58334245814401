import React, { Fragment, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from "reactstrap";


const Profile = () => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <Container fluid>
                  <Row>
                    <Col md="3" />
                    <Col md="6">
                      <div className="card mb-3 bg-success widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                          <div className="icon-wrapper-bg bg-white opacity-10" />
                          <i className="lnr-construction text-success" />
                        </div>
                        <div className="widget-numbers">{t('My Profile')}</div>
                        <div className="widget-subheading"></div>
                        <div className="widget-description text-white">
                          <span className="pe-1">{t('This Page is currently under development. We regret any inconvenience.')}</span>
                        </div>
                        <div className="widget-description text-white"></div>
                        <div className="widget-description text-white"></div>
                      </div>
                    </Col>
                    <Col md="3" />
                  </Row>
                </Container>
              </div>  
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

};
export default Profile;














// import React, { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import UserService from "../Users Management/users.service";


// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="invalid-feedback d-block">
//         This field is required!
//       </div>
//     );
//   }
// };

// const Profile = () => {

//   const currentUser = UserService.getCurrentUser();
//   const form = useRef();
//   const checkBtn = useRef();
//   const form1 = useRef();
//   const checkProfileBtn = useRef();

//   const navigate = useNavigate();

//   //For profile info
//   const [uid, setID] = useState(currentUser._id);
//   const [fullname, setFullName] = useState(currentUser.name);
//   const [status, setStatus] = useState(currentUser.status);
//   const [role_id, setRoleID] = useState(currentUser.role_id);

//   const [loadingprofile, setLoadingProfile] = useState(false);
//   const [messageprofile, setMessageProfile] = useState("");

//   const onChangeID = (e) => {
//     const uid = e.target.value;
//     setID(uid);
//   };

//   const onChangeFullName = (e) => {
//     const fullname = e.target.value;
//     setFullName(fullname);
//   };

//   const onChangeStatus = (e) => {
//     const status = e.target.value;
//     setStatus(status);
//   };

//   const onChangeRoleID = (e) => {
//     const role_id = e.target.value;
//     setRoleID(role_id);
//   };

//   const handleProfile = (e) => {
//     e.preventDefault();

//     setMessageProfile("");
//     setLoadingProfile(true);

//     form1.current.validateAll();

//     //For profile info
//     if (checkProfileBtn.current.context._errors.length === 0) {
//       console.log(uid);
//       console.log(fullname);
//       console.log(status);
//       console.log(role_id);
//       UserService.updateUserInfo(uid, fullname, status, role_id).then(
//         () => {
//           window.location.reload(false);
//           alert(t("Your Info is updated!"));
//         },
//         (error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();

//           setLoadingProfile(false);
//           setMessageProfile(resMessage);
//         }
//       );
//     } else {
//       setLoadingProfile(false);
//     }

//   };

//   //For password
//   const [loading, setLoading] = useState(false);
//   const [message, setMessage] = useState("");
//   const [password, setPassword] = useState("");
//   const [newpassword, setNewPassword] = useState("");
//   const [confirmpassword, setConfirmPassword] = useState("");

//   const onChangePassword = (e) => {
//     const password = e.target.value;
//     setPassword(password);
//   };
//   const onChangeNewPassword = (e) => {
//     const newpassword = e.target.value;
//     setNewPassword(newpassword);
//   };
//   const onChangeConfirmPassword = (e) => {
//     const confirmpassword = e.target.value;
//     setConfirmPassword(confirmpassword);
//   };


//   const handleLogin = (e) => {
//     e.preventDefault();

//     setMessage("");
//     setLoading(true);

//     form.current.validateAll();

//     //For password
//     if (checkBtn.current.context._errors.length === 0) {
      
//       UserService.changeUserPassword(uid,password, newpassword, confirmpassword).then(
//         () => {
//           navigate("/");
//           alert(t("Your Password Has been changed please LogIn again!"));
//           window.location.reload();
//         },
//         (error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();

//           setLoading(false);
//           setMessage(resMessage);
//         }
//       );
//     } else {
//       setLoading(false);
//     }
//   };
//   return (
//     <>
    
//       <div className="container-fluid">
//         { }
//         <div className="row">
//           <div className="col-sm-12">
//             <div className="page-title-box">
//               {/* <div className="float-end">
//                 <button type="button" className="btn btn-primary">
//                   Add New Profile
//                 </button>
//               </div> */}
//               <h4 className="page-title">Profile</h4>
//             </div>
//             { }
//           </div>
//           { }
//         </div>
//         { }

//         <div className="row">
//           <div className="col-lg-6 col-xl-6">
//             <div className="card">
//               <div className="card-header">
//                 <div className="row align-items-center">
//                   <div className="col">
//                     <h4 className="card-title">
//                       Personal Information
//                     </h4>
//                   </div>

//                 </div>{" "}

//               </div>

//               <div className="card-body">
//                 <Form onSubmit={handleProfile} ref={form1}>
//                   <Input
//                     type="hidden"
//                     name="uid"
//                     value={uid}
//                     onChange={onChangeID}
//                   />
//                   <div className="form-group mb-3 row">
//                     <label htmlFor="fullname" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Full Name
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="text"
//                         className="form-control"
//                         name="fullname"
//                         value={fullname}
//                         onChange={onChangeFullName}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   {/* <div className="form-group mb-3 row">
//                     <label htmlFor="role_id" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Role id
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="text"
//                         className="form-control"
//                         name="role_id"
//                         value={role_id}
//                         onChange={onChangeRoleID}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div> */}
//                   {/* <div className="form-group mb-3 row">
//                     <label htmlFor="status" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Status
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <select className="form-select" name="status" id="status" value={status}>
//                         <option value="0">Inactive</option>
//                         <option value="1">Active</option>
//                       </select>
//                     </div>
//                   </div> */}
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Email Address
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="las la-at" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.email + ""}
//                           placeholder="Email"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Created
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="las la-phone" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.datetime_added + ""}
//                           placeholder="Created on"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Last login
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="la la-globe" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.last_login + ""}
//                           placeholder="Last login Datetime"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Super Admin
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <select disabled className="form-select" name="super_admin" id="super_admin" value={currentUser.super_admin}>
//                         <option value="0">No</option>
//                         <option value="1">Yes</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <div className="col-lg-9 col-xl-8 offset-lg-3">
//                       <center>
//                         <button className="btn btn-custom-color btn-block me-2" disabled={loadingprofile}>
//                           {loading && (
//                             <span className="spinner-border spinner-border-sm"></span>
//                           )}
//                           Update
//                         </button>
//                       </center>
//                       {/* <button
//                         type="button"
//                         className="btn btn-de-danger"
//                       >
//                         Cancel
//                       </button> */}
//                     </div>
//                   </div>
//                   <CheckButton style={{ display: "none" }} ref={checkProfileBtn} />
//                 </Form>
//               </div>
//             </div>
//           </div>{" "}

//           <div className="col-lg-6 col-xl-6">
//             <div className="card">
//               <div className="card-header">
//                 <h4 className="card-title">Change Password</h4>
//               </div>

//               <div className="card-body">
//                 <Form onSubmit={handleLogin} ref={form}>
//                   <div className="form-group mb-3 row">
//                     <label htmlFor="password" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Current Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         name="password"
//                         value={password}
//                         onChange={onChangePassword}
//                         validations={[required]}
//                       />
//                       {/* <a
//                         href="/forgot-password"
//                         className="text-primary font-12"
//                       >
//                         Forgot password ?
//                       </a> */}
//                     </div>
//                   </div>

//                   <div className="form-group mb-3 row">
//                     <label htmlFor="newpassword" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       New Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         name="newpassword"
//                         placeholder="New-Password"
//                         value={newpassword}
//                         onChange={onChangeNewPassword}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Confirm Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         placeholder="Confirm New-Password"
//                         name="confirmpassword"
//                         value={confirmpassword}
//                         onChange={onChangeConfirmPassword}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <div className="col-lg-9 col-xl-8 offset-lg-3">
//                       <center>
//                         <button className="btn btn-custom-color btn-block me-2" disabled={loading}>
//                           {loading && (
//                             <span className="spinner-border spinner-border-sm"></span>
//                           )}
//                           Change Password
//                         </button>
//                       </center>
//                       {/* <button
//                         type="button"
//                         className="btn btn-de-danger">
//                         Cancel
//                       </button> */}
//                     </div>
//                   </div>
//                   {message && (
//                     <div className="form-group">
//                       <div className="alert alert-danger" role="alert">
//                         {message}
//                       </div>
//                     </div>
//                   )}
//                   <CheckButton style={{ display: "none" }} ref={checkBtn} />
//                 </Form>
//               </div>

//             </div>



//           </div>
//           {" "}

//         </div>
//       </div>
          
//     </>
//   );
// };

// export default Profile;
