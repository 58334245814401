import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers =  { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const get_cruds_dynamic_data = (id,filter) => {
  var payload = {
    functionName: "GetCrud",
    "postData":{
      "_id": id,
      "filter":filter
    }
  }
  return axios.post(API_URL, payload, {headers});
};


const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name":referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};

const getcollection = (referal_name,filter,fieldlist,limit,offset) => {
  var payload = {
    functionName: "GetDynCollectionData",
    postData: {
      "collection_name":referal_name,
      "login_user_id": storeduser._id,
      "fieldlist":fieldlist,
      "filter":filter,
      "limit":limit,
      "offset":offset
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, {headers});
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData":{
        "collection_name": "dynamic_modules",
        "filter":filter
    }
  }
  return axios.post(API_URL, payload, {headers});
};
const DynModuleService = {
  import_dyn_records,
  get_cruds_dynamic_data,
  deleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
};
  
export default DynModuleService;