import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import { format, parseISO, set } from 'date-fns';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import FilterByName from "../../components/Table Filters/FilterByName";
import FilterByEmail from "../../components/Table Filters/FilterByEmail";
import FilterByPhone from "../../components/Table Filters/FilterByPhone";
import FilterByDate from "../../components/Table Filters/FilterByDate";
import FilterByCountry from "../../components/Table Filters/FilterByCountry";
import FilterByStatus from "../../components/Table Filters/FilterByStatus";

import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import CustomersService from "./customers.service";



const CustomersList = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [CustomersList, setCustomers] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers('0');
  }, []); 

  function fetchCustomers(pg_index) {
    setLoading(true);
    CustomersService.getAll(pg_index).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      setCustomers(response.data.data);
      setFilteredItems(response.data.data);
      // setCustomers(response);
      // setFilteredItems(response);
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const TBcolumns = [
    {
      name: t('Email'),
      width: '20%',
      selector: row => row.email,
    },
    {
      name: t('Full Name'),
      width: '18%',
      selector: row => row.full_name,
    },
    {
      name: t('Phone Number'),
      width: '14%',
      selector: row => row.phone,
    },
    {
      name: t('Registration Date'),
      selector: row => row.reg_date,//format(parseISO(row.reg_date, new Date()), "dd-MMM-yyyy"),
    },
    {
      name: t('Country'),
      selector: row => row.country,
    },
    {
      name: t('Status'),
      width: '14%',
      selector: (row) =>  (
        <div>
          {row.status === "Active" &&
            <button className="btn btn-success btn">{row.status}
            </button>
          }
          {row.status === "Deactivated" &&
            <button className="btn btn-danger btn">{row.status}
            </button>
          }
        </div>
        ),
    },
    {
    name: t('Actions'),
    width: '10%',
    selector:  (row) =>  {
      return (
        <div>
          <Link to='/customer-details' state= {row} className="ViewCustomerWarranty" >
            <button className="mt-2 mb-2 me-2 btn-icon btn-link btn text-info">
              <i className="pe-7s-news-paper btn-icon-wrapper"> </i>
            </button>
          </Link>
        </div>
        
      );
    }
    },
  ];

  const [filteredItems, setFilteredItems] = useState([]);
  const [filterTextEmail, setfilterTextEmail] = React.useState('');
  const [filterTextName, setfilterTextName] = React.useState('');
  const [filterTextPhone, setfilterTextPhone] = React.useState('');
  const [filterTextDate, setfilterTextDate] = React.useState('');
  const [filterTextCountry, setfilterTextCountry] = React.useState('');
  const [filterTextStatus, setfilterTextStatus] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
 
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      setFilteredItems(CustomersList)
      if (filterTextEmail) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextEmail('');
      }
      if (filterTextName) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextName('');
      }
      if (filterTextPhone) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextPhone('');
      }
      if (filterTextDate) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextDate('');
      }
      if (filterTextCountry) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextCountry('');
      }
      if (filterTextStatus) {
        setResetPaginationToggle(!resetPaginationToggle);
        setfilterTextStatus('');
      }
    };
  
    return (
      <>
        <FilterByEmail onFilterEmail={e => setFilterTextFtn(e.target.value, 'email')} onClear={handleClear} filterTextEmail={filterTextEmail} />
        <FilterByName onFilterName={e => setFilterTextFtn(e.target.value, 'name')} onClear={handleClear} filterTextName={filterTextName} />
        <FilterByPhone onFilterPhone={e => setFilterTextFtn(e.target.value, 'phone')} onClear={handleClear} filterTextPhone={filterTextPhone}/>
        <FilterByDate onFilterDate={e => setFilterTextFtn(e.target.value, 'date')} onClear={handleClear} filterTextDate={filterTextDate} />
        <FilterByCountry onFilterCountry={e => setFilterTextFtn(e.target.value, 'country')} onClear={handleClear} filterTextCountry={filterTextCountry}/>
        <FilterByStatus onFilterStatus={e => setFilterTextFtn(e.target.value, 'status')} onClear={handleClear} filterTextStatus={filterTextStatus} />
      </>  
    );
  }, [filterTextEmail, filterTextName, filterTextPhone, filterTextDate, filterTextCountry, filterTextStatus, resetPaginationToggle]);

  function setFilterTextFtn(itemrec, param) {
    console.log('item:-', itemrec)
    if(param === 'email') {
      setfilterTextEmail(itemrec);
      const filterItems = CustomersList.filter(
        item => item.email && item.email.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'name') {
      setfilterTextName(itemrec);
      const filterItems = CustomersList.filter(
        item => item.full_name && item.full_name.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'phone') {
      setfilterTextPhone(itemrec);
      const filterItems = CustomersList.filter(
        item => item.phone && item.phone.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'date') {
      setfilterTextDate(itemrec);
      const filterItems = CustomersList.filter(
        item => item.reg_date.includes(itemrec),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'country') {
      setfilterTextCountry(itemrec);
      const filterItems = CustomersList.filter(
        item => item.country && item.country.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'status') {
      setfilterTextStatus(itemrec);
      const filterItems = CustomersList.filter(
        item => item.status && item.status.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Customers')}
                        <div className="page-title-subheading">
                          {t('View all customers with basic details.')}
                        </div>
                      </div>
                    </div>
                    {/* <div className="page-title-actions">
                      <a href="/add-entity">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">Add New</button>
                      </a>
                    </div> */}
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

};

export default CustomersList;




