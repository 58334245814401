import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";






class WarrantyDetails extends React.Component {
  
  
  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
    };
    this.toggle = this.toggle.bind(this);
    
  }

  
  

 
  

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
   

  render() {
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block mb-2 me-2">
            <button onClick={this.toggle} className="mt-2 mb-2 me-2 btn-icon btn-link btn text-info">
              <i className="pe-7s-news-paper btn-icon-wrapper"> </i>
            </button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
              <ModalHeader >{t('Warranty Details')}</ModalHeader>
              <ModalBody>
                <Card className="main-card mb-3">
                  <CardBody>
                    <table className="table table-striped table-bordered nowrap">
                      <tbody>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Product Model')}</th>
                          <td>{this.props.data.prod_model}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "35%" }}>{t('Product Serial No')}</th>
                          <td>{this.props.data.prod_serial_no}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Seller Name')}</th>
                          <td>{this.props.data.seller_name}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Date of Purchase')}</th>
                          <td>{new Date(this.props.data?.purchase_date).toDateString().split(' ').slice(1).join(' ')}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Date of Installation')}</th>
                          <td>{new Date(this.props.data?.install_date).toDateString().split(' ').slice(1).join(' ')}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Purchase Receipt')}</th>
                          <td>{this.props.data.receipt}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "30%" }}>{t('Date of Submission')}</th>
                          <td>{new Date(this.props.data?.submit_date).toDateString().split(' ').slice(1).join(' ')}</td>
                        </tr>
                        {/* <tr>
                          <th style={{ width: "30%" }}>Current Status</th>
                          <td>{this.props.data.status}</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button color="link" className="btn btn-custom-color btn-hover-shine me-3 btn" onClick={this.toggle}>
                  {t('Okay')}
                </Button>
              </ModalFooter>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default WarrantyDetails;