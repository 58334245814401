import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../pages/Users Management/users.service";


const ViewActivityLog = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [log, setLog] = useState("");
  const [user, setUser] = useState("");

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  },[]);

  function setData() {
    if(state) {
      console.log("Going to set data object for display")
      setLog(state)
      getuser(state.user_id);
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  }

  function getuser(id) {
    UserService.getSingleUser(id).then(
      (response) => {
        console.log("Get User response:-", response)
        setUser(response.data.data);
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
      },
  )};


  return(
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-display2 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Log Details')}
                        <div className="page-title-subheading">
                          {t('Following information for')} <strong> {log.collection_name}</strong> {t('has been')} <strong> {t('Updated')}</strong> {t('by')} <strong> Yasir</strong>
                          {log?.data_after?.datetime_modified &&
                          <span>&nbsp;{t('on')}&nbsp;<strong>{new Date(log?.data_after?.datetime_modified).toUTCString()}</strong></span>
                          } {t('from')} <strong> {log.ip}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="6">
                    <Card className="main-card mb-3">
                      <CardBody>
                          <table className="table table-striped table-bordered nowrap">
                              <tbody>
                                <tr>
                                  <th style={{width:"30%"}}>{t('User Name')}</th>
                                  <td>Yasir</td>
                                </tr>
                                <tr>
                                  <th style={{width:"30%"}}>{t('DB Table')}</th>
                                  <td>{log.collection_name}</td>
                                </tr>
                                <tr>
                                  <th style={{width:"30%"}}>{t('Record ID')}</th>
                                  <td>{log.record_id}</td>
                                </tr>
                                <tr>
                                  <th style={{width:"30%"}}>{t('Time')}</th>
                                    {log?.data_after?.datetime_modified &&
                                      <td>{new Date(log?.data_after?.datetime_modified).toUTCString()}</td>
                                    }
                                </tr>
                                <tr>
                                  <th style={{width:"30%"}}>{t('IP')}</th>
                                  <td>{log.ip}</td>
                                </tr>
                              </tbody>
                          </table>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <table className="table table-striped table-bordered nowrap">
                          <tbody>
                            <tr>
                              <th>{t('Field')}</th>
                              <th>{t('New Value')}</th>
                              <th>{t('Old Value')}</th>
                            </tr>
                            <tr>
                              <td>{t('last login date')}</td>
                              <td style={{color:"red"}}>2022-11-23 11:04:28</td>
                              <td>2022-11-22 13:51:34</td>
                            </tr>
                            <tr>
                              <td>{t('last login IP')}</td>
                              <td style={{color:"red"}}>::1</td>
                              <td>::1</td>
                            </tr>
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

};
export default ViewActivityLog;