import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";

import axios from "axios";
import { format, parseISO, set } from 'date-fns';
import { useNavigate, Link } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import CollectionFilter from "../../components/Table Filters/LogsFilters/CollectionFilter";
import ModuleFilter from "../../components/Table Filters/LogsFilters/ModuleFilter";
import FunctionFilter from "../../components/Table Filters/LogsFilters/FunctionFilter";
import DateFilter from "../../components/Table Filters/LogsFilters/DateFilter";

import DataTable from 'react-data-table-component';
import $ from 'jquery';

const API_URL  = process.env.REACT_APP_BASEURL;

const ActivityLogs = () => {
  
  const { t, i18n } = useTranslation();

  const [logslist, setlogslist] = useState([])
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');
  const [filterText4, setFilterText4] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getlogs(0, pageLimit);
  }, [pageLimit]);

  async function getlogs(page_index, page_limit) {
    const headers= { 
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    }
    var filter = {}
    if($("#colfilter").val() !=="")
    filter["collection_name"] =$("#colfilter").val();
    if($("#modfilter").val() !=="")
    filter["module_name"] =$("#modfilter").val();
    if($("#funcfilter").val() !=="")
    filter["function_name"] =$("#funcfilter").val();

    if(filterText4 !=="")
    filter["datetime_added"] =$("#datefilter").val();
      // "datetime_added": filterText4

    var offset = (page_index -1 ) * page_limit;
    if(offset < 1 ) offset=0;
    var getData = {
      functionName: "GetCollectionData",
      postData: {
        "collection_name": "audit_logs",
        "sort": {_id:-1},
        "limit": page_limit,
        "offset": offset,
        "filter":filter,
      },
    }
    setLoading(true);
    axios.post(API_URL, getData, {headers})
      .then((response) => {
        setLoading(false);
        const data = response.data.data;
        console.log("Get Loglist Resp:-", data);
        setlogslist(data);
        setFilteredItems(data);
        setTotalRows(response.data.count.count);
      }).catch((error) => {
        setLoading(false);
        console.log("API Error:-", error)
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          alert(t("Unable to retrieve data from Server"))
      });

  };

  function handlePageChange(page_index) {
    getlogs(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }
  
  const TBcolumns = [
    {
      name: t('Collection'),
      width: '22%',
      selector: row => row.collection_name,
    },
    {
      name: t('Action'),
      width: '15%',
      selector: row => row.action,
    },
    {
      name: t('Module'),
      width: '15%',
      selector: row => row.module_name,
    },
    {
      name: t('Function'),
      width: '20%',
      selector: row => row.function_name,
    },{
      name: t('Date'),
      width: '15%',
      selector: row => format(parseISO(row.datetime_added, new Date()), "dd-MMM-yyyy"),
    },
    {
      name: t('Actions'),
      width: '15%',
      selector:  (row) =>  {
        return (
          <div>
            <Link to='/view-activity-log' state= {row} >
              <button className="mt-2 mb-2 me-2 btn-icon btn-link btn text-info">
                <i className="pe-7s-news-paper btn-icon-wrapper"> </i>
              </button>
            </Link>
          </div>
        );
      }
    },
  ];
 
  const subHeaderComponentMemo = React.useMemo(() => {
    
    return (
      <>
        <CollectionFilter 
          onFilter1={e => setFilterTextFtn(e.target.value, 'collection')}
          handleKeyDown = {event => checkenterkey(event)}
          filterText1={filterText1}
          onClear={() => handleClear('collection')} 
        />
        <ModuleFilter
          handleKeyDown = {event => checkenterkey(event)} 
          onFilter2={e => setFilterTextFtn(e.target.value, 'module')} 
          filterText2={filterText2}
          onClear={e => handleClear('module')} 
        />
        <FunctionFilter 
          handleKeyDown = {event => checkenterkey(event)}
          onFilter3={e => setFilterTextFtn(e.target.value, 'function')}
          filterText3={filterText3}
          onClear={e => handleClear('function')} 
        />
        {/* <DateFilter 
          handlechange = {event => handledatechange(event)}
          onFilter4={e => setFilterTextFtn(e.target.value, 'date')}
          filterText4={filterText4} 
          onClear={handleClear} 
        /> */}
      </>  
    );
  }, [filterText1, filterText2, filterText3, filterText4, resetPaginationToggle]);


  function setFilterTextFtn(itemrec, param) {
    console.log('item:-', itemrec)
    if(param === 'collection') {
      setFilterText1(itemrec);
    }
    if(param === 'module') {
      setFilterText2(itemrec);
    }
    if(param === 'function') {
      setFilterText3(itemrec);
    }
    if(param === 'date') {
      setFilterText4(itemrec);
      // const filterItems = logslist.filter(
      //   item => item.datetime_added.includes(itemrec),
      // );
      // setFilteredItems(filterItems);
    }
  };

  function checkenterkey(event) {
    if (event.key === 'Enter') {
      var page = 0
      handlePageChange(page++)
    }
  }
  function handledatechange(event) {
    var page = 0
    setFilterText4($("#datefilter").val());
    handlePageChange(page++)
  }

  function handleClear(item) {
    console.log(item);
    if (item === 'collection') {
      
      $("#colfilter").val('');
      setFilterText1('');
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'module') {
      $("#modfilter").val('');
      setFilterText2('');
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      var page = 0
      handlePageChange(page++)
    }
  
    // if (item === 'collection') {
    //   console.log("value:-", item)
    //   console.log("filter text:-", filterText1)
    //   if (filterText1) {
        
    //     var local = '';
    //     setFilterText1(local);
    //     console.log("After filter text:-", filterText1)
    //   }
      
    // }
    // if (item === 'module') {
    //   console.log("value:-", item)
    //   setFilterText2('');
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   var page = 0
    //   handlePageChange(page++)
    // }
    // if (item === 'function') {
    //   console.log("value:-", item)
    //   setFilterText3('');
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   var page = 0
    //   handlePageChange(page++)
    // }
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-display2 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Activity Logs')}
                        <div className="page-title-subheading">
                          {t('View all Activity Logs. Click on a log to view its details.')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                            <DataTable
                              columns={TBcolumns}
                              data={filteredItems}
                              pagination
                              paginationServer
                              paginationTotalRows={totalRows}
                              onChangePage={handlePageChange}
                              onChangeRowsPerPage={handlePerRowsChange}
                              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                              subHeader
                              subHeaderComponent={subHeaderComponentMemo}
                              selectableRowDisabled={true}
                              persistTableHead
                            />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
}

export default ActivityLogs;