import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import DynModuleService from "./dynamicmodules.service";
import fileDownload from 'js-file-download'
import CollectionFilter from "../../components/Table Filters/LogsFilters/CollectionFilter";
import ModuleFilter from "../../components/Table Filters/LogsFilters/ModuleFilter";
import FunctionFilter from "../../components/Table Filters/LogsFilters/FunctionFilter";
import DateFilter from "../../components/Table Filters/LogsFilters/DateFilter";
import CommonService from "../../pages/common.service.js";

import DataTable from 'react-data-table-component';
import $ from 'jquery';
const API_URL  = process.env.REACT_APP_BASEURL;
const API2_URL  = process.env.REACT_APP_LOGINURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const DataTableView = () => {
  
  
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t, i18n } = useTranslation();
  
  var  pathname = location.pathname;
  pathname =  pathname.split('/')[2];
  var pathData = {}

  pathData.Parent_referal_name = "";
  pathData.redirect_param = "";
  pathData.Parentid = "";
  pathData.id = pathname;
  if(pathData.id){
    pathData.redirect_param = pathname;
    var text = pathname;
    if(text.indexOf("-") > -1){
      pathData.id = text.split('-')[0];
      pathData.Parentid = text.split('-')[1];
      pathData.Parent_referal_name = text.split('-')[2];
  
    }
  }

  const [collectionlist, setcollectionlist] = useState([]);
  const [pre_params, setpre_params] = useState(pathData)
  const [childlist, setchildlist] = useState([]);
  const [fieldlist, setfieldlist] = useState([]);
  const [datalist, setdatalist] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [basefilter, setbasefilter] = useState({});

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchChildlinksData();
    fetchDynamicData();
  }, []); 
  
 
  const fetchDynamicData = async () => {
    try {
        
        const response = await DynModuleService.get_cruds_dynamic_data(pre_params.id);
        const data = await response.data.data;
        
        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);

        var filter = basefilter;
        if(pre_params.Parentid !==""){

          var custom_fields = data[0].custom_fields;
          custom_fields.forEach((element) => { 
             if(element.parent_col === pre_params.Parent_referal_name ){
              filter[element.map]=pre_params.Parentid
              setbasefilter(filter)
             }
             
          });
          
        }
        const response1 = await DynModuleService.getcollection(data[0].referal_name,filter,data[0].custom_fields,pageLimit,currentPage);
        const responsedata = await response1.data;
        setdatalist(responsedata.data);
        setTotalRows(responsedata.count.count);
        settotalPages(responsedata.count.total_pages)
        if(responsedata.count.total_pages)
        $('#paging_stats').html('Page 1 of '+responsedata.count.total_pages);
        else
        $('#paging_stats').html('No Records');
        var endingat = pageLimit;if(endingat > totalRows){ endingat=responsedata.count.count }
        $('#record_stats').html('Showing 0 - '+endingat+' of '+responsedata.count.count+' records');
        
        
    } catch (error) {
        console.log("error", error);
    }
  };
const search_products = (e) => {
  
  if (e.key === 'Enter' || e.keyCode === 13) {
    var fieldsarr = fieldlist;
    if(fieldsarr[0]){
      var fields = basefilter;
      
      if(fields[fieldsarr[0].map] !== $("#searchfield").val()){
        fields['$or'] =[];
        if($("#searchfield").val() !=="" ){
          //fields[fieldsarr[0].map] = $("#searchfield").val()
          for(var i in fieldsarr){
            var filter_entry={};
            filter_entry[fieldsarr[i].map]=$("#searchfield").val();
            fields['$or'].push(filter_entry);
          }
        }else {
          fields['$or']=[];
          delete fields['$or']
        }
        

        setbasefilter(fields);
        fetchDynamicData();
      }
      
    } 
  }
}

  
  const get_collection_data = async (page_index) => {
    const response1 = await DynModuleService.getcollection(collectionlist.referal_name,basefilter,collectionlist.custom_fields,pageLimit,page_index);
    const responsedata = await response1.data;
    setdatalist(responsedata.data);
    
  }
  function nextPage(){
    var page = currentPage;
    page = page +1;
    if(page >  totalPages-1){
      
    }else{
      setcurrentPage(page)
      var offset  = page * pageLimit;
      get_collection_data(offset)
      pagination_text(offset,page+1)
    }

  }
  function prevPage(){
    var page = currentPage;
    page = page -1;
    if(page <  0 ){
      
    }else{
      setcurrentPage(page)
      var offset  = page * pageLimit;
      get_collection_data(offset)
      pagination_text(offset,page+1)
    }

  }
  function firstPage(){
    var page = 0;
    setcurrentPage(page)
    var offset  = page * pageLimit;
    get_collection_data(offset)
    pagination_text(offset,page+1)
  }
  function lastPage(){
    var page = totalPages-1;
    setcurrentPage(page)
    var offset  = page * pageLimit;
    get_collection_data(offset)
    pagination_text(offset,page+1)
  } 
  function pagination_text(offset,page){
    
    $('#paging_stats').html('Page '+page+' of '+totalPages);
    var startingfrom = offset;
    var endingat = offset + pageLimit;if(endingat > totalRows){ endingat=totalRows }
    $('#record_stats').html('Showing '+startingfrom+' - '+endingat+' of '+totalRows+' records');
    
      
  }
  function handlePageChange(page_index) {
    get_collection_data(page_index);
  }

  const fetchChildlinksData = async () => {
    try {
        const response = await DynModuleService.getAllCruds({"crud_id":pre_params.id});
        const data = await response.data.data;
        setchildlist(data);
    } catch (error) {
        console.log("error", error);
    }
  };
  const TBcolumns = [];

  
  const handleDownload = (filename) => {

    var columnslist = [];
    var valuelist = [];
    if(fieldlist.length > 0 ){

      fieldlist.map((field, index) => {
        
        columnslist.push(field.map);
        valuelist.push(field.field_lable);
      })
      var csvData = columnslist.join(',')+"\r\n"+valuelist.join(',');
    } else {

      var csvData = "";
    }
    
    fileDownload(csvData, filename)
   
  }
  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }
  function confirmDelete(_id,_referal_name) {
    let msg = t("Once deleted, you will not be able to recover this!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendDelete(_id,_referal_name)
      }
    }) 
  }

  function uploadFileButton(){
    
    //var form = $('form')[0];
    var formData = new FormData();
    formData.append('file', $('#fileUpload').prop('files')[0]);
    formData.append("login_user_id",storeduser._id)
    formData.append("id",pre_params.id)
    formData.append("Parentid",pre_params.Parentid)
    formData.append("functionName","ImportDynCollectionData")
    const headers =  { 
      'token': localStorage.getItem("session-id")
    }
    $.ajax({
      url: API2_URL+'/import_csv/',
      data: formData,
      type: 'POST',
      headers:headers,
      contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
      processData: false, // NEEDED, DON'T OMIT THIS
      success: function(response){
        toggleModal();
        Alerts.swalSuccessAlert(response.message)
        fetchDynamicData();

      }
  });
  }

  function sendDelete(id,referal_name) {
    setLoading(true);
    DynModuleService.deleteCollection(id,referal_name).then((response) => {
      setLoading(false);
      console.log("Delete Collection Data response:-", response)
      fetchDynamicData();
      if(response.data.status) {
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Delete operation failed"))
    })
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div>
                  <span className="d-inline-block mb-2 me-2">
                    <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
                      <ModalHeader >{t('Import Records')}</ModalHeader>
                      <ModalBody>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <form id="importform">
                              <input 
                                type='file' name="importfile" label={t('Upload')} accept='.csv' 
                                onChange={uploadFileButton} 
                                id="fileUpload"
                              />
                              <br/>
                              <p style={ {border:"3px solid red",padding:"5px",marginTop: "10px"}}>* Please do not remove or change the first row in the sample file.</p>
                            </form>

                          </CardBody>
                        </Card>
                      </ModalBody>
                      <ModalFooter>
                        <Button className="btn btn-success btn-hover-shine me-3 btn" onClick={() => {handleDownload('sample.csv')
                              }}>{t('Download Sample')}</Button>
                        <Button color="link" onClick={toggleModal} className="btn btn-danger btn-hover-shine me-3 btn">
                        {t('Cancel')}
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </span>
                </div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-server icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {collectionlist.page_title}
                        <div className="page-title-subheading">
                        {t('View and Delete')} {collectionlist.page_title}.
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions ">
                      <a href={"/adddata/"+pre_params.redirect_param} className="AddData">
                        <button type="button" className="btn btn-success btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a>
                    
                      
                     <button type="button" className="btn btn-danger btn-hover-shine me-3 btn ImportData" onClick={toggleModal}>{t('Import CSV')}</button> 
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                            <Col md="3"  style={{float:"right"}}>
                              <input
                                  type='text' className="form-control" name="searchfield" placeholder={t('Search Records ...')}
                                  id="searchfield" onKeyUp={search_products}
                                />
                            </Col>
                            <br/><br/>
                          <table className="table table-bordered table-striped table-hover mb-0 square border">
                            <thead className="thead-light">
                              <tr>
                                {fieldlist && fieldlist.map((field, index) => (
                                  <th key={"th"+index}>{field.field_lable}</th>
                                  ))}
                                  <th>{t('Actions')}</th>
                                </tr>
                              </thead>
                              <tbody>
                              {datalist && datalist.map((dataval, index) => (
                                <tr  key={"tr"+index}>
                                  
                                  {fieldlist && fieldlist.map((field, index1) => (
                                    <td key={index1+"td"+index} >{dataval[field.map]}</td>
                                  ))} 
                                  
                                  <td key={"ltd"+index}>
                                    {childlist && childlist.map((link, index2) => (
                                       
                                      <a href={"/viewdata/"+link._id+"-"+dataval._id+"-"+collectionlist.referal_name}  style={{cursor: "pointer"}}>
                                    
                                        <i className="pe-7s-news-paper btn-icon-wrapper icon-gradient bg-arielle-smile btn-lg px-1"> </i>
                                      
                                      </a>
                                    ))} 
                                    
                                    <Link to={"/editdata/"+pre_params.redirect_param} state={dataval} className="EditData" >
                                      <i className="lnr-pencil btn-icon-wrapper icon-gradient bg-arielle-smile btn-lg px-1"> </i>
                                    
                                    </Link>
                                    <a onClick={() => confirmDelete(dataval._id,collectionlist.referal_name)} style={{cursor: "pointer"}} className="DeleteData">
                                    
                                      <i className="lnr-trash btn-icon-wrappe icon-gradient bg-ripe-malin btn-lg px-0"> </i>
                                    
                                    </a>
                                  </td>
                                </tr>
                              ))}
                              </tbody>
                            </table>
                            <br/>
                            <div>
                              <nav class="sc-jOiSOi ksCHta rdt_Pagination">
                                <span class="sc-cOxWqc sc-khsqcC kSZUzF eBqHmd" id="record_stats"> </span> 
                               
                                <span class="sc-bCfvAP gEnHjZ" style={{float:"right"}}>
                                  <div>
                                    <span id="paging_stats" ></span>
                                    <button onClick={firstPage}   style={{border:"none",background:"white",fill:"#8d8a8a"}} id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" class="sc-dPWrhe fXiHqP">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                        </svg>
                                    </button>
                                    <button onClick={prevPage} style={{border:"none",background:"white",fill:"#8d8a8a"}} id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" class="sc-dPWrhe fXiHqP">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                      </svg>
                                    </button>
                                    <button onClick={nextPage} style={{border:"none",background:"white",fill:"#8d8a8a"}} id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="true" disabled="" class="sc-dPWrhe fXiHqP">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                      </svg>
                                    </button>
                                    <button onClick={lastPage} style={{border:"none",background:"white",fill:"#8d8a8a"}} id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="true" disabled="" class="sc-dPWrhe fXiHqP">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    </svg>
                                    </button>
                                  </div>
                                </span>
                                
                              </nav>
                            </div>
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );


  
  
  }

export default DataTableView;