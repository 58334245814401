import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';

import Alerts from "../../components/Alerts/alerts";

import RoleService from "./roles.service";



const RolesList = () => {
 
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchRoles();
  }, []); 


  function fetchRoles() {
    setLoading(true);
    RoleService.getAll().then((response) => {
      setLoading(false);
      console.log("Get Roles response:-", response)
      setRoles(response.data.data);
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const TBcolumns = [
    {
      name: t('Name'),
      selector: row => row.name,
    },
    {
      name: t('Date'),
      selector: row => format(parseISO(row.datetime_added, new Date()), "dd-MMM-yyyy")
    },
    {
    name: t('Actions'),
    selector:  (row) =>  {
      return (
        <div>
          <Link className="EditData" to="/update-role" state={row} >
            <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
          </Link>
          <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{cursor: "pointer"}}>
            <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
          </a>
        </div>
        
      );
    }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = roles.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };
  return (
    <GenFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  );
}, [filterText, resetPaginationToggle]);


  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this role!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendDelete(_id)
      }
    }) 
  };


  function sendDelete(id) {
    setLoading(true);
    RoleService.deleteRole(id).then((response) => {
      setLoading(false);
      console.log("Delete role response:-", response)
      fetchRoles();
      if(response.data.status) {
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Role Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Role at the moment."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Delete operation failed"))
    })
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-study icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('User Roles')}
                        <div className="page-title-subheading">
                          {t('View all User Roles, Create, Edit and Delete a user role.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <a href="/add-role" className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

  
  

  
}


export default RolesList;



// return (
//   <>
//     <div className="container-fluid">
//       <br/>
//       <div className="row">
//         <div className="col-sm-12">
//           <div className="page-title-box">
//             <div className="float-end">
//               <a href="/addrole">
//               <button type="button" className="btn btn-custom-color">Add New</button>
//               </a>
//             </div>
//             <h4 className="page-title">Roles Management</h4>
//           </div>
//         </div>
//       </div>
//       <br/>
//       <div className="row">
//         <div className="col-lg-12">
//           <div className="card">
//             <div className="card-body">
//               <div className="table-responsive">
//                 <DataTable
//                   columns={TBcolumns}
//                   data={filteredItems}
//                   pagination
//                   paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
//                   subHeader
//                   subHeaderComponent={subHeaderComponentMemo}
//                   selectableRowDisabled={true}
//                   persistTableHead
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
// );