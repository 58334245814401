import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import UserService from "../../pages/Users Management/users.service";
import RoleService from "../Roles/roles.service";

library.add(
  faLockOpen,
  faLock
  );


const UsersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getusers();
    getroles();
  },[])

  function getusers() {
    setLoading(true);
    UserService.getAllUsers().then((response) => {
      setLoading(false);
      console.log("Get Users response:-", response)
      setUsers(response.data.data);
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    },
  )};

  const getroles = async () => {
    try {
      const response = await RoleService.getAll();
      const data = await response.data.data;
      setRoles(data);
      console.log("Roles:-", data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const TBcolumns = [
    {
      name: t('Users'),
      width: '15%',
      selector: row => row.name,
    },
    {
      name: t('Role'),
      width: '15%',
      selector: (row) => {
        for (let i = 0; i < roles.length; i++) {
          if(row.role_id === roles[i]._id){
            return (
              <div>
                {roles[i].name}
              </div>
            );
          }
        }
      }
    },
    {
    name: t('Email'),
    width: '27%',
    selector: row => row.email,
    },
    {
    name: t('Contact #'),
    width: '13%',
    selector: row => row.contact,
    },
    {
    name: t('Current Status'),
    width: '14%',
    selector: (row) =>  (
      <div>
        {row.status === "1" &&
          <button type="button" 
            className="btn btn-success btn-hover-shine me-3 btn">{t('Active')}
          </button>
        }
        {row.status === "0" &&
          <button type="button" 
            className="btn btn-danger btn-hover-shine me-3 btn">{t('Deactivated')}
          </button>
        }
      </div>
      ),
    },
    {
    name: t('Actions'),
    width: '16%',
    selector:  (row) =>  (
        <div>
          <Link to="/update-user" state={row} className="EditData">
            <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
          </Link>
          {row.status === "1" &&
            <a onClick={() => changestatus(row)} style={{cursor: "pointer"}} className="text-info btn-lg px-0">
              <FontAwesomeIcon icon={["fas", "lock-open"]}  />
            </a>
          }
          {row.status === "0" &&
            <a onClick={() => changestatus(row)} style={{cursor: "pointer"}} className="text-danger btn-lg px-0">
              <FontAwesomeIcon icon={["fas", "lock"]}  />
            </a>
          }
         
          { <a onClick={() => confirmDelete(row._id)} style={{cursor: "pointer"}}>
            <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
          </a> }
        </div>
        
      ),
    },
  ];
  
  const filteredItems = users.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );
  // document.getElementById("search").focus(); 
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <GenFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  function changestatus(_user) {
    console.log("User data:-", _user)
    let msg = "Are you sure you want to change the current status of this User?"
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendUpdatedStatus(_user)
      }
    })
  };

  function sendUpdatedStatus(userdata) {
    var getData = {
      functionName: "UpdateUser",
      postData: {
        name: userdata.name,
        email:userdata.email,
        contact:userdata.contact,
        role_id: userdata.role_id,
        status: "",
        _id: userdata._id
      },
    }
    if(userdata.status === "1") {
      getData.postData.status = "0"
    }
    else {
      getData.postData.status = "1"
    }
    setLoading(true);
    UserService.updateUser(getData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      getusers();
      if(response.data.status){
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Status Updated Successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update status at the moment."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Operation failed."))
    });
  }

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this user!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendDelete(_id)
      }
    }) 
  };

  function sendDelete(id) {
    setLoading(true);
    UserService.deleteUser(id).then((response) => {
      console.log("Delete User response:-", response)
      setLoading(false);
      getusers();
      if(response.data.status) {
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("User Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the User."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Delete operation failed"))
    })
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Users Management')}
                        <div className="page-title-subheading">
                        {t('View all Users, Create, Edit and Delete a User.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <a href="/add-user"  className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
}

export default UsersList;















// function confirmDelete(_id) {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="react-confirm-alert-body">
  //           <div className='custom-ui'>
  //             <div className="logo_div">
  //               <img
  //               src={"assets/images/trash.png"}
  //               alt="logo-small"
  //               className="logo-sm logo-dark delete_icon"
  //               />
  //             </div>
  //             <h4 className="react-confirm-alert-body-h4">Delete User?</h4>
  //             <p className="react-confirm-alert-body-p">Do you really want to delete this user? You will not be able to recover it.</p>
  //             <div className="react-confirm-alert-button-group">
  //               <button className="btn btn-soft-secondary btn-block me-2" onClick={onClose}>Cancel</button>
  //               <button className="btn btn-danger btn-block"
  //                 onClick={() => {
  //                   sendDelete(_id)
  //                   onClose();
  //                 }}
  //               >
  //                 Yes, delete it
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // }

{/* <table className="table table-hover mb-0 square border">
                    <thead className="thead-light">
                      <tr>
                        <th>Users</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {users && users.map((user, index) => (
                      <tr key = {index}>
                        <td>
                          <img 
                            src="assets/images/users/user-8.jpg"
                            alt='image'
                            className="rounded-circle thumb-xs me-1"
                          />
                          {user.name}
                        </td>
                        <td>Plumber</td>
                        <td>{user.email}</td>
                        <td>{user.contact}</td>
                        <td>
                          <span className="badge badge-soft-success" >{user.status == '1' && "Active"}</span>
                          <span className="badge badge-soft-danger" >{user.status == '0' && "Inactive"}</span>
                        </td>
                        <td>
                          <Link to='/add-user' state= {user} >
                            <i className="las la-pen text-secondary font-16" />
                          </Link>
                          <a onClick={() => deleteuser(user._id)} style={{cursor: "pointer"}}>
                            <i className="las la-trash-alt text-secondary font-16" />
                          </a>
                        </td>
                      </tr>
                    ))}
                    </tbody>
</table> */}
