import React, { Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import TabGenralConfig from "./TabGenralConfig";
import TabEmailConfig from "./TabEmailConfig";



const Configuration = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  },[]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title mb-0">
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <i className="pe-7s-settings icon-gradient bg-happy-green" />
                </div>
                <div>{t('Configuration')}
                  <div className="page-title-subheading">
                    {t('View and update some basic configurations.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Tabs defaultActiveKey="1" renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                <TabPane tab={t('General')} key="1" >
                  <TabGenralConfig className="btn-custom-color" />
                </TabPane>
                <TabPane tab={t('Email')} key="2" >
                  <TabEmailConfig className="btn-custom-color" />
                </TabPane>
              </Tabs>
            </div>
          </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default Configuration; 