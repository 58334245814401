import React, { Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { useTranslation } from 'react-i18next';

import ConfigurationService from "./pages/Configuration/configuration.service";

import Leftbar from "./components/Left Bar/Leftbar";
import Topbar from "./components/Top Bar/Topbar";

import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword";

import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";

import CustomersList from "./pages/Customers/CustomersList";
import ViewCustomer from "./pages/Customers/View Customer/ViewCustomer";

import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "./pages/EmailTemplates/AddEmailTemplate";
import UpdateEmailTemplate from "./pages/EmailTemplates/UpdateEmailTemplate";
import ViewEmailTemplate from "./pages/EmailTemplates/ViewEmailTemplate";

import Configuration from "./pages/Configuration/Configuration";

import ActivityLogs from "./pages/Activity Logs/ActivityLogs";
import ViewActivityLog from "./pages/Activity Logs/ViewActivityLog";

import EntitiesList from "./pages/Entities/EntitiesList";
import AddEntity from "./pages/Entities/AddEntity";
import UpdateEntity from "./pages/Entities/UpdateEntity";

import AttributesList from "./pages/Attributes/AttributesList";
import AddAttribute from "./pages/Attributes/AddAttribute";
import UpdateAttribute from "./pages/Attributes/UpdateAttribute";

import RolesList from "./pages/Roles/RolesList";
import AddRole from "./pages/Roles/AddRole";
import UpdateRole from "./pages/Roles/UpdateRole";

import UsersList from "./pages/Users Management/UsersList";
import AddUser from "./pages/Users Management/AddUser";
import UpdateUser from "./pages/Users Management/UpdateUser";

import DataTableView from "./pages/Dynamic Modules/DataTableView";
import InsertionForm from "./pages/Dynamic Modules/InsertionForm";
import UpdationFrom from "./pages/Dynamic Modules/UpdationFrom";

import CustomerLogin from "./pages/Customer Panel/Authentication/CustomerLogin";
import CustomerForgotpass from "./pages/Customer Panel/Authentication/CustomerForgotpass";
import CustomerResetpass from "./pages/Customer Panel/Authentication/CustomerResetpass";
import CustomerRegistration from "./pages/Customer Panel/Authentication/CustomerRegistration";
import RegisterProduct from "./pages/Customer Panel/Products/RegisterProduct";
import WarrantiesList from "./pages/Customer Panel/Warranties/WarrantiesList";



const App = () => {

  const {t, i18n } = useTranslation();

  useEffect(() => {
    getColors();
    // getSetLanguage();
  }, []);

  function getColors() {
    const buttonsColor = localStorage.getItem("buttons_color");
    const topbarColor = localStorage.getItem("topbar_color");
    const leftbarColor = localStorage.getItem("leftbar_color");
    const buttonsTextColor = localStorage.getItem("buttons_text_color");
    setColors(buttonsColor, topbarColor, leftbarColor, buttonsTextColor);
  }

  function setColors(buttons_color, topbar_color, leftbar_color, buttons_text_color) {
    document.documentElement.style.setProperty('--buttons-color', buttons_color);
    document.documentElement.style.setProperty('--topbar-color', topbar_color);
    document.documentElement.style.setProperty('--leftbar-color', leftbar_color);
    document.documentElement.style.setProperty('--buttons-text-color', buttons_text_color);
  }

  // function getSetLanguage() {
  //   if(localStorage.getItem("language")) {
  //     const language = localStorage.getItem("language");
  //     i18n.changeLanguage(language);
  //   }
  //   else {
  //     localStorage.setItem('language', 'en');
  //     i18n.changeLanguage('en');
  //   }
  // }

    localStorage.setItem("user_type", 'admin');
    const userType = localStorage.getItem("user_type");
    const register = localStorage.getItem("register");
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const reset = localStorage.getItem("reset");
    const resetpass = localStorage.getItem("resetpass");
    const regprod = localStorage.getItem("regprod"); 
    if (!currentUser && !reset && !resetpass && !regprod && userType === 'admin') {
      console.log("I am at checkpoint 1")
      return <Login />
    }

    if(reset && userType === 'admin') {
      return <ForgotPassword />
    }
  
    if(resetpass && userType === 'admin') {
      return <ResetPassword />
    }

    if(regprod && userType === 'admin') {
      return <RegisterProduct />
    }
  
    if (!currentUser && !reset && !resetpass && !register && userType === 'customer') {
      console.log("I am at checkpoint 2")
      return <CustomerLogin />
    }
    
    if(reset && userType === 'customer') {
      return <CustomerForgotpass />
    }
  
    if(resetpass && userType === 'customer') {
      return <CustomerResetpass />
    }
  
    if(register && userType === 'customer') {
      return <CustomerRegistration />
    }


  return (
    <ResizeDetector handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
            "app-container app-theme-white",
            { "fixed-header": true },
            { "fixed-sidebar": true || width < 1500 },
            { "fixed-footer": true },
            { "closed-sidebar": true || width < 1500 },
            {
              "closed-sidebar-mobile": true || width < 1500,
            },
            { "sidebar-mobile-open": false },
            { "body-tabs-shadow-btn": true }
            )}>
              <Topbar />
              <div className="app-main">
                <Leftbar />
                <Routes>
                  <Route exact path={"/"} element={<Dashboard />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/auth-password" element={<ResetPassword />} />
                  <Route exact path="/forgot-password" element={<ForgotPassword />} />

                  <Route exact path={"/home"} element={<Dashboard />} />
                  <Route exact path="/profile" element={<Profile />} />

                  <Route exact path="/customers-list" element={<CustomersList />} />
                  <Route exact path="/customer-details" element={<ViewCustomer />} />

                  <Route path="/email-templates" element={<EmailTemplates />} />
                  <Route path="/add-email-template" element={<AddEmailTemplate />} />
                  <Route path="/update-email-template" element={<UpdateEmailTemplate />} />
                  <Route path="/view-email-template" element={<ViewEmailTemplate />} />

                  <Route exact path="/configuration" element={<Configuration />} />

                  <Route exact path="/activity-logs" element={<ActivityLogs />} />
                  <Route exact path="/view-activity-log" element={<ViewActivityLog />} />

                  <Route exact path="/entities-list" element={<EntitiesList />} />
                  <Route exact path="/add-entity" element={<AddEntity />} />
                  <Route exact path="/update-entity" element={<UpdateEntity />} />

                  <Route exact path="/attributes-list" element={<AttributesList />} />
                  <Route exact path="/add-attribute" element={<AddAttribute />} />
                  <Route exact path="/update-attribute" element={<UpdateAttribute />} />

                  <Route exact path="/roles-list" element={<RolesList />} />
                  <Route exact path="/add-role" element={<AddRole />} />
                  <Route exact path="/update-role" element={<UpdateRole />} />

                  <Route exact path="/users-list" element={<UsersList />} />
                  <Route exact path="/add-user" element={<AddUser />} />
                  <Route exact path="/update-user" element={<UpdateUser />} />

                  <Route path="/viewdata/:id" element={<DataTableView />} />
                  <Route path="/adddata/:id" element={<InsertionForm />} />
                  <Route path="/editdata/:id" element={<UpdationFrom />} />

                  <Route path="/customer/login" element={<CustomerLogin />} />
                  <Route path="/customer/forgot-password" element={<CustomerForgotpass />} />
                  <Route path="/customer/reset-password" element={<CustomerResetpass />} />
                  <Route path="/customer/register" element={<CustomerRegistration />} />
                  <Route path="/customer/register-product" element={<RegisterProduct />} />
                  <Route path="/customer/warranties-list" element={<WarrantiesList />} />

                </Routes>
              </div>
          </div>
        </Fragment>
      )}
    />
  );
};

export default App;

// <Route exact path="/users" element={<UsersList />} />
// <Route path="/users/:id" element={<Users />} />
// <Route path="/datatable" element={<DataTableTest />} />
