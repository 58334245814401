import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import WarrantyDetails from '../../../components/Modals/WarrantyDetails';



const TabWarranty = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [warranty, setwarranty] = useState([]);
  

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  }, []);

  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      //   setLog(state)
      //   getuser(state.user_id);
      setwarranty(state.warrantyData)
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  }

  const TBcolumns = [
    {
      name: t('Name/Model'),
      selector: row => row.prod_model,
    },
    {
      name: t('Product Serial No'),
      selector: row => row.prod_serial_no,
    },
    {
      name: t('Date of Purchase'),
      selector: row => new Date(row.purchase_date).toDateString().split(' ').slice(1).join(' '),
    },
    {
    name: t('Actions'),
    selector:  (row) =>  {
      return (
        <WarrantyDetails data={row} />
      );
      }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = warranty.filter(
    item => item.prod_model && item.prod_model.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };
  return (
    <GenFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  );
}, [filterText, resetPaginationToggle]);


  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <LoadingOverlay tag="div" active={loading}
                styles = {{overlay: (base) => ({...base})}}
                spinner = {<DNALoader/>}>
                <DataTable
                  columns={TBcolumns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  selectableRowDisabled={true}
                  persistTableHead
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default TabWarranty;