import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { Form, Field } from "react-final-form";

import Alerts from "../../../components/Alerts/alerts";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";



const CustomerResetpass = () => {
  localStorage.removeItem("resetpass");
  
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();

  const validateFtn = (values) => {
    const errors = {};
    if (!values.resetcode) {
      errors.resetcode = "Reset Code Required.";
    }
    if (!values.password) {
      errors.password = "New Password Required.";
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = "Confirm New Password Required.";
    }
    if (values.password !== values.confirmpassword) {
      errors.confirmpassword = "Passwords Do Not Match.";
    }
    return errors;
  };

  const onSubmit = values => {
    console.log("Input Data:-", values)
    // setLoading(true);
    // AuthService.resetpw(values.password, values.confirmpassword, values.resetcode).then((response) => {
    //   console.log("Response:-", response)
    //   setLoading(false);
    //   Alerts.swalSuccessAlert("Password is reset successfully. Please login again.")
    //   navigate("/login");
    //   window.location.reload();
    // },
    // (error) => {
    //   const resMessage =
    //     (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    //     setLoading(false);
    //     if(resMessage) {
    //       if(resMessage === 'Network Error') { 
    //         Alerts.swalErrorAlert("Server Error, Please try again later.")
    //       }
    //       else {
    //         Alerts.swalErrorAlert(resMessage)
    //       }
    //     }
    //     else {
    //       Alerts.swalErrorAlert("Server Error, Please try again later.")
    //     }
    //   }
    // );
  };

  return (
    <div className="h-100 bg-light-gray bg-animation">
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
          <Form onSubmit={onSubmit}
            validate={validateFtn}
            render={({ handleSubmit, values, submitting, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-dialog w-100 mx-auto shadow-sm">
                  <LoadingOverlay tag="div" active={loading}
                    styles={{overlay: (base) => ({...base})}}
                    spinner = {<DNALoader/>}>
                    <div className="modal-content border-light">
                      <div className="modal-body">
                      <div className="h5 modal-title text-center">
                        <img src={logo} className="app-logo mb-3 mt-3" />
                          <div className="mb-1">
                          Reset Password?
                          </div>
                          <h6 className="mb-5">
                            <span>Use the form below to reset it.</span>
                          </h6>
                        </div>
                        <div>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="resetcode">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="code">Reset Code</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder="Reset Code"
                                      />
                                      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="password">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="newpass">New Password</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="New Password"
                                      />
                                      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="confirmpassword">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="newpass">Confirm New Password</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="Confirm New Password"
                                      />
                                      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                                
                              <div className="d-grid my-3">
                                <Button className="btn-custom-color" size="lg" type="submit" disabled={!valid}>
                                  Reset Password
                                </Button>
                              </div>
                              <div className="text-center mb-3">
                                <a href="/login" className="link-btn-custom-text-color">
                                  Sign in existing account
                                </a>
                              </div>
                            </div>
                        </div>
                      </div>
                      
                    </div>
                  </LoadingOverlay>
                </div>
              </form>
            )}
          />
        </Col>
      </div>
    </div>
  );
}

export default CustomerResetpass;


// return (
  //   <div className="col-md-12 login-form">
  //     <div className="container-md">
  //       <div className="row vh-100 d-flex justify-content-center">
  //         <div className="col-12 align-self-center">
  //           <div className="card-body">
  //             <div className="row">
  //               <div className="col-lg-4 mx-auto">
  //                 <div className="card">
  //                   <div className="card-body pt-4 p-0">
  //                     <div className="text-center p-3">
  //                       <a href="/" className="logo logo-admin mb-3">
  //                         <img
  //                           src="assets/images/basesystemslogo.svg"
  //                           height={50}
  //                           alt="logo"
  //                           className="auth-logo"
  //                         />
  //                       </a>
  //                       {/* <h4 className="mt-3 mb-1 fw-semibold text-white font-18">
  //                         Let's Get Started Metrica
  //                       </h4> */}
  //                       <p className="text-muted mb-0 mt-3">
  //                         Sign in to continue to Base Systems
  //                       </p>
  //                     </div>
  //                   </div>
  //                   <div className="card-body pt-0">
  //                     {/* <form className="my-4" action="index.html"> */}

  //                     <Form onSubmit={handleLogin} ref={form}>
  //                       <div className="form-group">
  //                         <label htmlFor="username">Username</label>
  //                         <Input
  //                           type="text"
  //                           className="form-control"
  //                           name="username"
  //                           value={username}
  //                           onChange={onChangeUsername}
  //                           validations={[required]}
  //                         />
  //                       </div>

  //                       <div className="form-group">
  //                         <label htmlFor="resetcode">Reset Code</label>
  //                         <Input
  //                           type="resetcode"
  //                           className="form-control"
  //                           name="resetcode"
  //                           value={resetcode}
  //                           onChange={onChangeResetcode}
  //                           validations={[required]}
  //                         />
  //                       </div>

  //                       <div className="form-group">
  //                         <label htmlFor="password">Password</label>
  //                         <Input
  //                           type="password"
  //                           className="form-control"
  //                           name="password"
  //                           value={password}
  //                           onChange={onChangePassword}
  //                           validations={[required]}
  //                         />
  //                       </div>

  //                       <div className="form-group mb-0 row">
  //                         <div className="col-12">
  //                           <div className="d-grid mt-3">
  //                             <button className="btn btn-danger" disabled={loading}>
  //                               {loading && (
  //                                 <span className="spinner-border spinner-border-sm"></span>
  //                               )}
  //                               Log In <i className="fas fa-sign-in-alt ms-1" />
  //                             </button>

  //                           </div>
  //                         </div>
  //                         { }
  //                       </div>

  //                       {message && (
  //                         <div className="form-group">
  //                           <div className="alert alert-danger" role="alert">
  //                             {message}
  //                           </div>
  //                         </div>
  //                       )}
  //                       <CheckButton style={{ display: "none" }} ref={checkBtn} />
  //                     </Form>

  //                     <div className="m-3 text-center text-muted">
  //                       <p className="mb-0">
  //                         Remember It ?
  //                         <a
  //                           href="/login"
  //                           className="text-primary ms-2"
  //                         >
  //                           Sign in here
  //                         </a>
  //                       </p>
  //                     </div>
  //                     {/* <hr className="hr-dashed mt-4" />
  //                     <div className="text-center mt-n5">
  //                       <h6 className="card-bg px-3 my-4 d-inline-block">
  //                         Or Login With
  //                       </h6>
  //                     </div>
  //                     <div className="d-flex justify-content-center mb-1">
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-primary rounded-circle me-2"
  //                       >
  //                         <i className="fab fa-facebook align-self-center" />
  //                       </a>
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-info rounded-circle me-2"
  //                       >
  //                         <i className="fab fa-twitter align-self-center" />
  //                       </a>
  //                       <a
  //                         href
  //                         className="d-flex justify-content-center align-items-center thumb-sm bg-soft-danger rounded-circle"
  //                       >
  //                         <i className="fab fa-google align-self-center" />
  //                       </a>
  //                     </div> */}
  //                   </div>
  //                   { }
  //                 </div>
  //                 { }
  //               </div>
  //               { }
  //             </div>
  //             { }
  //           </div>
  //           { }
  //         </div>
  //         { }
  //       </div>
  //       { }
  //     </div>
  //   </div>
  // );
