import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import EntitiesService from "./entities.service";


const AddEntity = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cruds, setcruds] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEntities();
  },[]);

  function fetchEntities() {
    EntitiesService.getAll().then((response) => {
      console.log("Get Entities response:-", response)
      setcruds(response.data.data);
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const onSubmit = values => {
    
    var payloadData = {
      functionName: "AddCrud",
      postData: {
        name: values.name,
        page_title: values.page_title,
        menu_label: values.menu_label,
        menu_positon: values.menu_positon,
        referal_name: values.referal_name,
        showIn: values.showIn,
        crud_id: values.crud_id
      },
    }
    setLoading(true);
    EntitiesService.addEntity(payloadData).then((response) => {  
      
      setLoading(false);
      if(response.data.status){
        navigate("/entities-list")
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Entity added successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add new entity at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });

  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("Entity Name Required.");
    }
    if (!values.page_title) {
      errors.page_title = t("Entity Title Required.");
    }
    if (!values.menu_label) {
      errors.menu_label = t("Menu Label Required.");
    }
    if (!values.menu_positon) {
      errors.menu_positon = t("Menu Position Required.");
    }
    if (!values.referal_name) {
      errors.referal_name = t("Referal Name Required.");
    }
    return errors;
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-center">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add New Entity')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add a new Entity.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-6">
            <Card className="main-card mb-3">
              <CardBody>
                <Form onSubmit={onSubmit}
                  validate={validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row form>
                          <Col md={12}>
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="name">{t('Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="page_title">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="page_title">{t('Page Title')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Page Title')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="menu_label">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_label">{t('Menu Label')}</Label>
                                    <Input {...input} type="text"
                                      invalid={meta.error && meta.touched}
                                      placeholder={t('Entity Menu Label')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="menu_positon">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_positon">{t('Menu Postion')}</Label>
                                    <Input {...input} type="number" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Menu Position')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="referal_name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="referal_name">{t('Collection Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Collection Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="showIn">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="showIn">{t('Show In')}</Label>
                                    <Input {...input} type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose and option--')}</option>
                                      <option key="0" value ="menu">{t('Menu')}</option>
                                      <option key="1" value ="new_page">{t('Nested Entity')}</option>
                                      
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            
                            <FormGroup>
                              <Field name="crud_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="crud_id">{t('Parent Entity')}</Label>
                                    <Input {...input}  type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose and option--')}</option>
                                      {cruds &&
                                        cruds.map((crud, inde) => (
                                          <option key = {inde} value={crud._id}>{crud.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Add Entity')}</button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default AddEntity;


