import React, { useState, useRef } from "react";
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";

import Alerts from "../../../components/Alerts/alerts";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";



const CustomerForgotpass = () => {

  localStorage.removeItem("reset");

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();


  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = "Email Required.";
    }
    else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email address.";
    }
    return errors;
  };

  const onSubmit = values => {
    console.log(values);
    localStorage.setItem("resetpass", true)
    navigate("/customer/reset-password");
    window.location.reload();
    // setLoading(true);
    // AuthService.pwresetreq(values.email).then((response) => {
    //   console.log("Response:-", response)
    //   setLoading(false);
    //   if(response.data.status){
    //     localStorage.setItem("resetpass", true)
    //     navigate("/auth-password");
    //     window.location.reload();
    //     if(response.data.message !== '') {
    //       Alerts.swalSuccessAlert(response.data.message)
    //     }
    //     else {
    //       Alerts.swalSuccessAlert("Email is sent successfully. Please check your email and reset your password.")
    //     }
    //   }
    //   else {
    //     if(response.data.message !== '') {
    //       Alerts.swalErrorAlert(response.data.message)
    //     }
    //     else {
    //       Alerts.swalErrorAlert("Error: Can't send reset code at the moment. Please try again later.")
    //     }
    //   }
    // },
    // (error) => {
    //   const resMessage =
    //   (error.response && error.response.data &&  error.response.data.message) || error.message || error.toString();
    //   setLoading(false);
    //   // localStorage.setItem("resetpass", true)//should be removed once API is fixed.
    //   // navigate("/auth-password");//should be removed once API is fixed.
    //   // window.location.reload();//should be removed once API is fixed.
    //   if(resMessage) {
    //     if(resMessage === 'Network Error') { 
    //       Alerts.swalErrorAlert("Server Error, Please try again later.")
    //     }
    //     else {
    //       Alerts.swalErrorAlert(resMessage)
    //     }
    //   }
    //   else {
    //     Alerts.swalErrorAlert("Server Error, Please try again later.")
    //   }
    // });
  };

  return (
    <div className="h-100 bg-light-gray bg-animation">
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
          <Form onSubmit={onSubmit}
            validate={validateFtn}
            render={({ handleSubmit, values, submitting, validating, valid }) => (
              <form onSubmit={handleSubmit}>                
                <div className="modal-dialog w-100 mx-auto shadow-sm">
                  <LoadingOverlay tag="div" active={loading}
                    styles = {{overlay: (base) => ({...base})}}
                    spinner = {<DNALoader/>}>
                    <div className="modal-content border-light">
                      <div className="modal-body p-5">
                      <div className="h5 modal-title text-center">
                      <img src={logo} className="app-logo mb-3" />
                          <div className="mb-1">
                            Forgot your Password?
                          </div>
                          <h6 className="mt-1 mb-4 opacity-8">
                            <span>Use the form below to recover it.</span>
                          </h6>
                        </div>
                        <div>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="mb-4">
                                      <Label for="email">Email</Label>
                                      <Input {...input} type="email"
                                        invalid={meta.error && meta.touched}
                                        placeholder="Enter Email"
                                      />
                                      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        
                      <div className="clearfix">
                        <div className="d-grid mb-4">
                          <Button className="btn-custom-color" size="lg" type="submit" disabled={!valid}>
                            Reset Password
                          </Button>
                        </div>
                        
                          <h6 className="text-center">
                            <a href="/login" className="link-btn-custom-text-color">
                              Sign in existing account
                            </a>
                          </h6>
                      </div>
                      </div>
                    </div>
                  </LoadingOverlay>
                </div>
              </form>
            )}
          />
        </Col>
      </div>
    </div>
  );

};

export default CustomerForgotpass;










  // return (
  //     <div id="body" className="auth-page">
  //       {}
  //       <div className="container-md">
  //         <div className="row vh-100 d-flex justify-content-center">
  //           <div className="col-12 align-self-center">
  //             <div className="card-body">
  //               <div className="row">
  //                 <div className="col-lg-4 mx-auto">
  //                   <div className="card">
  //                     <div className="card-body pt-4 p-0">
  //                       <div className="text-center p-3">
  //                         <a href="index.html" className="logo logo-admin mb-3">
  //                           <img
  //                             src="assets/images/basesystemslogo.svg"
  //                             height={50}
  //                             alt="logo"
  //                             className="auth-logo"
  //                           />
  //                         </a>
  //                         <p className="text-muted mb-0 mt-3">
  //                           Type your email address to reset your password.
  //                         </p>
  //                       </div>
  //                     </div>
  //                     <div className="card-body pt-0">
  //                       {/* <form className="my-4" action="index.html"> */}

  //                       <Form onSubmit={handleLogin} ref={form}>

  //                       <div className="form-group mb-2">
  //                         <label htmlFor="username">Email</label>
  //                         <Input
  //                           type="text"
  //                           className="form-control"
  //                           name="username"
  //                           value={username}
  //                           onChange={onChangeUsername}
  //                           validations={[required]}
  //                         />
  //                       </div>
  //                         {/* <div className="form-group mb-3">
  //                           <label className="form-label" htmlFor="username">
  //                             Email
  //                           </label>
  //                           <input
  //                             type="text"
  //                             className="form-control"
  //                             id="username"
  //                             name="username"
  //                             placeholder="Enter Email"
  //                           />
  //                         </div> */}
                          
  //                         <div className="form-group mb-0 row">
  //                           <div className="col-12">
  //                             <div className="d-grid mt-3">
  //                               <button className="btn btn-danger" disabled={loading}>
  //                                 {loading && (
  //                                   <span className="spinner-border spinner-border-sm"></span>
  //                                 )}
  //                                 Reset <i className="fas fa-sign-in-alt ms-1" />
  //                               </button>

  //                             </div>
  //                           </div>
  //                           { }
  //                         </div>

  //                         {message && (
  //                         <div className="form-group">
  //                           <div className="alert alert-danger" role="alert">
  //                             {message}
  //                           </div>
  //                         </div>
  //                       )}
  //                       <CheckButton style={{ display: "none" }} ref={checkBtn} />
  //                       </Form>
  //                       <div className="m-3 text-center text-muted">
  //                         <p className="mb-0">
  //                           Remember It ?
  //                           {/* <a
  //                             href="/login"
  //                             className="text-primary ms-2"
  //                           > */}
  //                           <a onClick={() => gotologin()} style={{cursor: "pointer"}} className="text-primary ms-2">
  //                             Sign in here
  //                           </a>
  //                         </p>
  //                       </div>
  //                     </div>
  //                     {}
  //                   </div>
  //                   {}
  //                 </div>
  //                 {}
  //               </div>
  //               {}
  //             </div>
  //             {}
  //           </div>
  //           {}
  //         </div>
  //         {}
  //       </div>
  //       {}
  //     </div>
  // );

