import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";


import {Card, CardBody } from "reactstrap";
import AttributesService from "./attributes.service";
import EntitiesService from "../Entities/entities.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";


const AddAttribute = () => {

  const { t, i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage(localStorage.getItem('language'));
      fetchEntities();
      fetchAttributes();
      addRow1();
    },[]);
    
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    
    const form = useRef();
    const checkBtn = useRef();
    const [loading, setLoading] = useState(false);
    const [cruds, setcruds] = useState([]);
    const [rows, setrows] = useState([]);
    const [parents, setparents] = useState([]);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    var formdata ={};
    
    function fetchEntities() {
      EntitiesService.getAll().then((response) => {
        setcruds(response.data.data);
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
        if(errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
    };

    function fetchAttributes() {
      AttributesService.getAll({crud_id:{"$ne":state.crud_id }}).then((response) => {
        
        setparents(response.data.data);
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
        if(errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setMessage("");
      setSuccessful(false);
      let data = new FormData(e.target);
      let formdata = {};
      for (let [key, value] of data) {
        if (formdata[key] !== undefined) {
          if (!Array.isArray(formdata[key])) {
            formdata[key] = [formdata[key]];
          }
          formdata[key].push(value);
        } else {
          formdata[key] = value;
        }
      }
      
      var labelss = formdata['label[]'];
      var valuesss = formdata['value[]'];
      
      let options=[];
      if(Array.isArray(labelss)){
        for(var i=0; i < labelss.length ;i++){
          if(labelss[i] && valuesss[i])
          options.push({'label':labelss[i],'value':valuesss[i]});
        }
      }
      else {
        if(labelss && valuesss )
        options.push({'label':labelss,'value':valuesss});
      }
      formdata.options = options;
      formdata.map = "Custom1"
        var payloadData = {
          functionName: "AddCustomFields",
          postData:  formdata 
        }
        setLoading(true);
        console.log("Attribute payload:-", payloadData)
        AttributesService.addAttribute(payloadData).then((response) => {  
          
          setLoading(false);
          if(response.data.status){
            navigate("/attributes-list",{ state: {"_id":state.crud_id } })
            if(response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message)
            }
            else {
              Alerts.swalSuccessAlert(t("Attribute added successfully."));
            }
          }
          else {
            if(response.data.message !== '') {
              Alerts.swalErrorAlert(response.data.message)
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
            }
          }
        },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });
    };


  function addRow1(){
    setrows(1);
  }

  function addRow(){
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);
  }


  function remove_option(index){
    document.getElementById('parent'+index).remove();
  }

    return (
      <>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper justify-content-center">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="pe-7s-plus icon-gradient bg-happy-green" />
                  </div>
                  <div>{t('Add New Attribute')}
                    <div className="page-title-subheading">
                      {t('Fill the form below to add a new Attribute.')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="d-flex justify-content-center">
              <div className="col-md-12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles = {{overlay: (base) => ({...base})}}
                      spinner = {<DNALoader/>}>  
                      <Form onSubmit={handleSubmit} ref={form}>
                        {!successful && (
                          <div>
                            <div className="row" >
                              <div className="col-md-6 col-xl-6" >
                                <div className="form-group">
                                  <label htmlFor="name">{t('Field Label')}</label>
                                  <Input type="text" className="form-control" placeholder={t('Attribute Field Label')}  name="field_lable" id="field_lable"    />
                                </div>
                                </div> 
                                <div className="col-md-6 col-xl-6" >
                                  <div className="form-group">
                                    <label htmlFor="page_title">{t('Field Type')}</label>
                                    <select className="form-select" name="field_type"  id="field_type"    >
                                      <option disabled={true} value="">{t('--Choose and option--')}</option>
                                      <option key="0" value ="text">{t('Text Input')}</option>
                                      <option key="1" value ="number">{t('Number Input')}</option>
                                      <option key="2" value ="email">{t('Email Input')}</option>
                                      <option key="3" value ="password">{t('Password Input')}</option>
                                      <option key="4" value ="dropdown">{t('Drop Down')}</option>
                                      <option key="5" value ="checkbox">{t('CheckBox')}</option>
                                      <option key="6" value ="radio">{t('Radio Button')}</option>
                                      <option key="7" value ="file">{t('File Input')}</option>
                                    </select>
                                  </div>
                                </div>
                            </div>                      
                            <br/>
                            <div className="row">
                              <div className="col-md-6 col-xl-6">
                                <div className="form-group " >
                                  <label htmlFor="menu_label">{t('Select Entity')}</label>
                                  <select className="form-select" name="crud_id"   id="crud_id" value={state.crud_id}  >
                                    <option disabled={false} value="">{t('--Choose and option--')}</option>
                                    {cruds &&
                                      cruds.map((crud, inde) => (
                                      <option key = {inde} value={crud._id}>{crud.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-xl-6">
                                <div className="form-group " >
                                  <label htmlFor="referal_name">{t('Select Parent')}</label>
                                  <select className="form-select" name="field_parent"   id="field_parent"    >
                                    <option disabled={false} value="">{t('--Choose and option--')}</option>
                                    {parents &&
                                      parents.map((parent, inde) => (
                                      <option key = {inde} value={parent._id}>{parent.field_lable}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                              
                                
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Is Editable')}</label>
                                    <select className="form-select" name="is_editable"   id="is_editable"      >
                                        <option disabled={true}  value="">{t('--Choose and option--')}</option>
                                        <option key="1" value ="1">{t('Yes')}</option>
                                        <option key="0" value ="0">{t('No')}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                            <div className="col-md-6 col-xl-6">
                              <div className="form-group" >
                                <label htmlFor="referal_name">{t('Show in DataTable')}</label>
                                <select className="form-select" name="datatable"   id="datatable"       >
                                  <option disabled={true}  value="">{t('--Choose and option--')}</option>
                                  <option key="1" value ="1">{t('Yes')}</option>
                                  <option key="0" value ="0">{t('No')}</option>
                                </select>
                              </div>
                                </div>
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                        <label htmlFor="referal_name">{t('Is Required')}</label>
                                        <select className="form-select" name="is_required"   id="is_required"       >
                                            <option disabled={false}  value="">{t('--Choose and option--')}</option>
                                            <option key="1" value ="1">{t('Yes')}</option>
                                            <option key="0" value ="0">{t('No')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            <br/>
                            <div className="row">
                              <div className="col-md-6 col-xl-6">
                                <h5>{t('Add Attribute Options (if any)')}</h5>
                              </div>
                            </div>   
                            <br/>
                            {Array.from({ length: rows }, (_, index) => (
                            <div className="row " id={"parent"+index}>
                              <div className="col-md-6 col-xl-6" >
                                <div className="form-group">
                                  <label htmlFor="name">{t('Option Label')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Attribute Option Label')} id={"option_lable"+index} name="label[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-5 col-xl-5" >
                                  <div className="form-group">
                                    <label htmlFor="name">{t('Option Value')}</label>
                                      <Input type="text" className="form-control" placeholder={t('Attribute Option Value')} id={"option_value"+index}  name="value[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-1 col-xl-1" >
                                  <div className="form-group">
                                    <br/>
                                    <a onClick={() =>remove_option(index)} style={{ cursor: "pointer" }}>
                                      <button className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger">
                                        <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                      </button>
                                    </a>
                                  </div>
                                </div> 
                            </div>
                            ))}
                              
                            <br/> 
                            <div className="row">
                              <div className="col-md-2 col-xl-2" >
                                <a  onClick={() => addRow()} className="btn btn-custom-color btn-hover-shine">{t('Add More')}</a>
                              </div>
                            </div> 
                            <br/>
                            <div className="form-group">
                              <div className="col-md-12 col-xl-12 ">
                                <center>
                                  <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                  <button type="submit" className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Add Attribute')}</button>
                                </center>
                              </div>
                            </div>
                          </div>
                        )}

                        {message && (
                          <div className="form-group">
                            <br/>
                            <div
                              className={
                                successful ? "alert alert-success" : "alert alert-danger"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );


}
export default AddAttribute;






















// import React, { useState, useEffect, Fragment } from "react";
// import { useNavigate } from "react-router-dom";
// import { render } from "react-dom";
// import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import arrayMutators from "final-form-arrays";
// import { FieldArray } from "react-final-form-arrays";

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../components/Loaders/DnaLoader";
// import "../../assets/custom css/loadoverlay.scss";

// import Alerts from "../../components/Alerts/alerts";

// import AttributesService from "./attributes.service";
// import EntitiesService from "../Entities/entities.service";

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const AddAttribute = () => {

//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [EntitiesList, setEntities] = useState([]);
//   const [Attributeslist, setAttributes] = useState([]);
//   const [rows, setrows] = useState([]);

//   useEffect(() => {
//     fetchEntities();
//     fetchAttributes();
//     // addRow1();
//   },[]);

//   function fetchEntities() {
//     EntitiesService.getAll().then((response) => {
//       console.log("Get Entities response:-", response)
//       setEntities(response.data.data);
//     },
//     (error) => {
//       const errContent =
//       (error.response && error.response.data) || error.message || error.toString();
//       if(errContent) {
//         Alerts.swalErrorAlert(errContent)
//       }
//       else {
//         Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//       }
//     })
//   };

//   function fetchAttributes() {
//     AttributesService.getAll().then((response) => {
//       console.log("Get Attributes response:-", response)
//       setAttributes(response.data.data);
//     },
//     (error) => {
//       const errContent =
//       (error.response && error.response.data) || error.message || error.toString();
//       if(errContent) {
//         Alerts.swalErrorAlert(errContent)
//       }
//       else {
//         Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//       }
//     })
//   };


//   const validateFtn = (values) => {
//     const errors = {};
//     if (!values.field_lable) {
//       errors.field_lable = "Attribute Field label Required.";
//     }
//     if (!values.field_type) {
//       errors.field_type = "Please select Field Type.";
//     }
//     if (!values.crud_id) {
//       errors.crud_id = "Please select an Entity.";
//     }
//     if (!values.field_parent) {
//       errors.field_parent = "Please select an Parent Field.";
//     }
//     if (!values.parenty_type) {
//       errors.parenty_type = "Please select an Parenty Type.";
//     }
//     if (!values.is_editable) {
//       errors.is_editable = "Please select an option.";
//     }
//     if (!values.datatable) {
//       errors.datatable = "Please select an option.";
//     }
//     if (!values.is_required) {
//       errors.is_required = "Please select an option.";
//     }
//     return errors;
//   };

//   var formData = {
    
//     options: [
//       {
//         label: "",
//         value: ""
//         //hotspots: [{}]
//       },
//     ]
//   };

//   const onSubmit = async values => {
//     await sleep(300);
//     console.log(values);
    
//     var payloadData = {
//       functionName: "AddCustomFields",
//       postData:  values 
//       // postData: {
//         // field_lable: values.field_lable,
//         // field_type: values.field_type,
//         // field_parent: values.field_parent,
//         // parenty_type:values.parenty_type,
//         // is_editable: values.is_editable,
//         // datatable: values.datatable,
//         // is_required: values.is_required,
//         // map : "Custom1",
//         // options:  options
//         // label: values.label,
//         // value: values.value
//       // },
//     }
//     // setLoading(true);
//     console.log("Attribute payload:-", payloadData)
//     // AttributesService.addAttribute(payloadData).then((response) => {        
//     //   console.log("API Response:-", response);
//     //   setLoading(false);
//     //   if(response.data.status){
//     //     navigate("/attributes-list")
//     //     if(response.data.message !== '') {
//     //       Alerts.swalSuccessAlert(response.data.message)
//     //     }
//     //     else {
//     //       Alerts.swalSuccessAlert(t("Attribute added successfully.");
//     //     }
//     //   }
//     //   else {
//     //     if(response.data.message !== '') {
//     //       Alerts.swalErrorAlert(response.data.message)
//     //     }
//     //     else {
//     //       Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
//     //     }
//     //   }
//     // },
//     // (error) => {
//     //   setLoading(false);
//     //   const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//     //   Alerts.swalErrorAlert(t("Network Operation failed."))
//     // });
//   };


// return (
//   <Fragment>
//     <div className="app-main__outer">
//       <div className="app-main__inner">
//         <div className="app-page-title">
//           <div className="page-title-wrapper justify-content-center">
//             <div className="page-title-heading">
//               <div className="page-title-icon">
//                 <i className="pe-7s-plus icon-gradient bg-happy-green" />
//               </div>
//               <div>Add New Attribute
//                 <div className="page-title-subheading">
//                   Fill the form below to add a new Attribute.
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
        
//       <div className="d-flex justify-content-center">
//         <div className="col-md-12">
//           <Card className="main-card mb-3">
//             <CardBody>
//               <Form onSubmit={onSubmit}
//                 validate={validateFtn}
//                 initialValues={{ 
//                   ...formData 
//                 }}
//                 mutators={{
//                   ...arrayMutators
//                 }}
//                 render={({ handleSubmit, form: {mutators: { push, pop }}, form, values, submitting, validating, valid }) => (
//                   <form onSubmit={handleSubmit}>
//                     <LoadingOverlay tag="div" active={loading}
//                       styles = {{overlay: (base) => ({...base})}}
//                       spinner = {<DNALoader/>}>  
//                       <Row className="d-flex">
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="field_lable">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="field_lable">Field Label</Label>
//                                   <Input {...input} type="text" 
//                                     invalid={meta.error && meta.touched} 
//                                     placeholder="Attribute Field Label"
//                                   />
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="field_type">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="field_type">Field Type</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     <option key="0" value ="text">Text Input</option>
//                                     <option key="1" value ="number">Number Input</option>
//                                     <option key="2" value ="email">Email Input</option>
//                                     <option key="3" value ="password">Password Input</option>
//                                     <option key="4" value ="dropdown">Drop Down</option>
//                                     <option key="5" value ="checkbox">CheckBox</option>
//                                     <option key="6" value ="radio">Radio Button</option>
//                                     <option key="7" value ="file">File Input</option>
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                       </Row>
//                       <Row  className="d-flex">
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="crud_id">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="crud_id">Select Entity</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     {EntitiesList &&
//                                       EntitiesList.map((entity, inde) => (
//                                       <option key = {inde} value={entity._id}>{entity.name}</option>
//                                       ))
//                                     }
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="field_parent">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="field_parent">Select Parent</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     {Attributeslist &&
//                                       Attributeslist.map((attribute, inde) => (
//                                       <option key = {inde} value={attribute._id}>{attribute.field_lable}</option>
//                                       ))
//                                     }
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                       </Row>
//                       <Row  className="d-flex">
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="parenty_type" >
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="parenty_type">Parenty Type</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     <option key="0" value ="grab_option">Grab Options</option>
//                                     <option key="1" value ="foriegn_key">Foriegn Key</option>
//                                     <option key="2" value ="display">Display yes/no</option>
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="is_editable" >
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="is_editable">Is Editable</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     <option key="1" value ="1">Yes</option>
//                                     <option key="0" value ="0">No</option>
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                       </Row>
//                       <Row  className="d-flex">
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="datatable">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="datatable">Datatable</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     <option key="1" value ="1">Yes</option>
//                                     <option key="0" value ="0">No</option>
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                           <FormGroup className="me-5">
//                             <Field name="is_required">
//                               {({ input, meta }) => (
//                                 <div>
//                                   <Label for="is_required">Is Required</Label>
//                                   <Input {...input}  type="select" 
//                                     invalid={meta.error && meta.touched} >
//                                     <option disabled={true} value="">--Choose and option--</option>
//                                     <option key="1" value ="1">Yes</option>
//                                     <option key="0" value ="0">No</option>
//                                   </Input>
//                                   {meta.error && meta.touched && 
//                                   <span className="text-danger">{meta.error}</span>}
//                                 </div>
//                               )}
//                             </Field>
//                           </FormGroup>
//                         </Col>
//                       </Row>
//                       <Row>
//                         <Col md={6} className="mt-3 mb-2">
//                           <h5>Add Attribute Options (if any)</h5>
//                         </Col>
//                       </Row>
//                       <FieldArray name="options">
//                         {({ fields }) =>
//                           fields.map((name, index) => (
                          
//                             <Row  className="d-flex" key={name} index={index}>
//                               <Col md={6} >
//                                 <FormGroup className="me-5">
//                                   <Field name={`${name}.label`}>
//                                     {({ input }) => (
//                                       <div>
//                                         <Label >Option Label</Label>
//                                         <Input {...input} type="text" 
//                                           placeholder="Attribute Option Label"
//                                         />
//                                       </div>
//                                     )}
//                                   </Field>
//                                 </FormGroup>
//                               </Col>
//                               <Col md={5} >
//                                 <Field name={`${name}.value`}>
//                                   {({ input }) => (
//                                     <div>
//                                       <Label>Option Value</Label>
//                                       <Input {...input} type="text" 
//                                         placeholder="Attribute Option Value"
//                                       />
//                                     </div>
//                                   )}
//                                 </Field>
//                               </Col>
//                               <Col md={1} className="d-flex">
//                                 <FormGroup className="d-flex align-items-center mt-4">
//                                   <div >
//                                     <a onClick={() => fields.remove(index)} style={{ cursor: "pointer" }}>
//                                       <button className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger">
//                                         <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
//                                       </button>
//                                     </a>
//                                   </div>
//                                 </FormGroup>
//                               </Col>
                                 
//                             </Row>
                          
//                         ))}
//                       </FieldArray>
//                       <div>
                        
                         
//                         <a onClick={() => push('options', undefined)}  className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-success">
//                           Add More
//                         </a>
                    
//                         </div>
                     
//                       <Row  >
//                         <Col md={12} >
//                           <FormGroup>
//                             <div className="d-flex justify-content-center">
//                               <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">Cancel</button>
//                               <button type="submit" className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>Add Attribute</button>
//                             </div>
//                           </FormGroup>  
//                         </Col>
//                       </Row>
//                     </LoadingOverlay>
//                   </form>
//                 )}
//               /> 
//             </CardBody>
//           </Card>
//         </div>
//       </div>
      
//       </div>
//     </div>
//   </Fragment>
// );

// }
// export default AddAttribute;