import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import UserService from "../../pages/Users Management/users.service";
import RoleService from "../Roles/roles.service";


const UpdateUser = () => {
  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const { state } = location;
  console.log("Received Data:-", state)

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getroles();
  },[]);

  const getroles = async () => {
    try {
      const response = await RoleService.getAll();
      const data = await response.data.data;
      setRoles(data);
      console.log("Roles:-", data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "UpdateUser",
      postData: {
        name: values.name,
        password: values.password,
        contact:values.contact,
        role_id: values.role_id,
        status: values.status,
        _id: state._id
      },
    }
    setLoading(true);
    UserService.updateUser(payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        navigate("/users-list")
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("User updated successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update user at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });

  }

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.name) {
      errors.name = t("User Name Required.");
    }
    if (!values.contact) {
      errors.contact = t("Contact No Required.");
    }
    if (!values.role_id) {
      errors.role_id = t("Please select a role.");
    }
    if (!values.status) {
      errors.status = t("Please select status.");
    }
    return errors;
  };
    
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-center">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update User')}
                  <div className="page-title-subheading">
                  {t('Edit the form below to update a User.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-6">
            <Card className="main-card mb-3">
              <CardBody>
                <Form onSubmit={onSubmit}
                  initialValues={state}
                  validate={validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row form>
                          <Col md={12}>
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="username">{t('User Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('User Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="contact">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="contact">{t('Contact No')}</Label>
                                    <Input {...input} type="number" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('User Contact No')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="role_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="role">{t('Role')}</Label>
                                    <Input {...input}  type="select" 
                                      invalid={meta.error && meta.touched}
                                    >
                                      <option disabled={true} value="">{t('--Choose and option--')}</option>
                                      {roles &&
                                        roles.map((role, inde) => (
                                          <option key = {inde} value={role._id}>{role.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="status">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="status">Status</Label>
                                    <Input {...input} type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose and option--')}</option>
                                      <option value="1">{t('Activate')}</option>
                                      <option value="0">{t('Deactivate')}</option>
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Update User')}</button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default UpdateUser;