
import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const headers = { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (page_index) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData":{
      "collection_name": "customers",
      "limit": 10,
      "offset": (page_index)*10
    }
  }
  return axios.post(API_URL, payload, {headers});
};




const CustomersService =  {
  getAll
}
  
export default CustomersService;