import axios from "axios";

const GET_API_URL  = process.env.REACT_APP_CONFIGURL;
const SET_API_URL = process.env.REACT_APP_BASEURL;

  
const headers =  { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}
  
const getConfiguration = () => {
  var payload = {
    functionName: "GetConfigurations",
    postData: {},
  }
  return axios.post(GET_API_URL, payload, {headers});
};

const setConfiguration = payload => {
  return axios.post(SET_API_URL, payload, {headers});
};

const ConfigurationService = {
  getConfiguration,
  setConfiguration
};
  
export default ConfigurationService;

