import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;

const headers= { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (payload) => {
  return axios.post(API_URL, payload, {headers});
};

const DashboardService = {
    getAll
  };
  
  export default DashboardService;