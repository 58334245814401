import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import DataTable from 'react-data-table-component';
import DashboardService from "./dashboard.service";

import WarrantyDetails from '../../components/Modals/WarrantyDetails';

const Home = () => {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [warranty, setwarranty] = useState([]);
  const [dashstates, setDashStates] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchStates();
  }, []);

  function fetchStates() {
    var getData = {
      functionName: "DashboardStats",
      postData: {
          offset: 0,
          limit: 10
      },
    }
    setLoading(true);
    DashboardService.getAll(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      setDashStates(response.data.data);
      setwarranty(response.data.data.waranties)
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      console.log("Net:-", errContent)
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };


  const TBcolumns = [
    {
      name:  t('Customer Name'),
      selector: row => row.full_name,
    },
    {
      name:  t('Product Name'),
      selector: row => row.warrantyData.prod_model,
    },
    {
      name:  t('Product Serial #'),
      selector: row => row.warrantyData.prod_serial_no,
    },
    {
      name:  t('Date of Purchase'),
      selector: row => new Date(row.warrantyData.purchase_date).toDateString().split(' ').slice(1).join(' '),
    }
  ];
  

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t('Dashboard')}
                      </div>
                    </div>
                    <div className="page-title-actions">
                      {/* <a href="/add-email-template" className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a> */}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab z-index-6">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          <i className="header-icon lnr-chart-bars icon-gradient bg-happy-green"> {" "} </i>
                          {t('Analytics')}
                        </div>
                      </CardHeader>
                      <Row className="g-0">
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-10 bg-warning" />
                              <i className="lnr-users text-dark opacity-8" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Total Customers')}</div>
                              <div className="widget-numbers">{dashstates.customers_count}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-danger" />
                              <i className="lnr-gift text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Total Products')}</div>
                              <div className="widget-numbers">{dashstates.products_count}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="12" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-success" />
                              <i className="lnr-list text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Total Warranty Requests')}</div>
                              <div className="widget-numbers">{dashstates.warranty_count}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                      <Card className="mb-3">
                        <CardHeader className="card-header-tab">
                          <div className="card-header-title font-size-lg text-capitalize fw-normal">
                            <i className="header-icon lnr-dice me-3 text-muted opacity-6"> {" "} </i>
                            {t('New Warranty Requests')}
                          </div>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay tag="div" active={loading}
                                styles = {{overlay: (base) => ({...base})}}
                                spinner = {<DNALoader/>}>
                                <DataTable
                                    columns={TBcolumns}
                                    data={warranty}
                                    selectableRowDisabled={true}
                                    persistTableHead
                                />
                            </LoadingOverlay>
                          </CardBody>
                      </Card>
                  </Col>
                    </Row>
              </div> 
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;








    
  // return (
  //   <>

  //     <div className="container-fluid">

  //       <div className="row">
  //         <div className="col-sm-12">
  //           <div className="page-title-box">
  //             <div className="float-end">
  //               <ol className="breadcrumb">
  //                 <li className="breadcrumb-item">
  //                   <a href="/">Metrica</a>
  //                 </li>

  //                 <li className="breadcrumb-item">
  //                   <a href="/">Project</a>
  //                 </li>

  //                 <li className="breadcrumb-item active">Dashboard</li>
  //               </ol>
  //             </div>
  //             <h4 className="page-title">Dashboard</h4>
  //           </div>

  //         </div>

  //       </div>

  //       <div className="row">
  //         <div className="col-md-6">
  //           <div className="row justify-content-center">
  //             <div className="col-md-6 col-lg-6">
  //               <div className="card report-card">
  //                 <div className="card-body">
  //                   <div className="row d-flex justify-content-center">
  //                     <div className="col">
  //                       <p className="text-dark mb-1 fw-semibold">
  //                         Projects
  //                       </p>
  //                       <h4 className="my-1">77</h4>
  //                       <p className="mb-0 text-truncate text-muted">
  //                         <span className="text-success">
  //                           <i className="mdi mdi-checkbox-marked-circle-outline me-1" />
  //                         </span>
  //                         26 Project Complete
  //                       </p>
  //                     </div>
  //                     <div className="col-auto align-self-center">
  //                       <div className="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
  //                         <i
  //                           data-feather="layers"
  //                           className="align-self-center text-muted icon-sm"
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>

  //                 </div>

  //               </div>

  //             </div>

  //             <div className="col-md-6 col-lg-6">
  //               <div className="card report-card">
  //                 <div className="card-body">
  //                   <div className="row d-flex justify-content-center">
  //                     <div className="col">
  //                       <p className="text-dark mb-1 fw-semibold">Tasks</p>
  //                       <h4 className="my-1">41</h4>
  //                       <p className="mb-0 text-truncate text-muted">
  //                         <span className="badge badge-soft-success">
  //                           Active
  //                         </span>{" "}
  //                         Weekly Avg.Sessions
  //                       </p>
  //                     </div>
  //                     <div className="col-auto align-self-center">
  //                       <div className="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
  //                         <i
  //                           data-feather="check-square"
  //                           className="align-self-center text-muted icon-sm"
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //           </div>

  //           <div className="row">
  //             <div className="col">
  //               <div className="card">
  //                 <div className="card-header">
  //                   <div className="row align-items-center">
  //                     <div className="col">
  //                       <h4 className="card-title">Today's Tasks</h4>
  //                     </div>

  //                   </div>

  //                 </div>

  //                 <div className="card-body">
  //                   <header className="jumbotron">
                      
  //   <div>
  //     {console.log("content")}
  //     {console.log(content)}
  //   </div>;
  //                   </header>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-md-6">
  //           <div className="card">
  //             <div className="card-body">

  //               <ul className="nav nav-tabs" role="tablist">
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project1_Tab"
  //                     role="tab"
  //                   >
  //                     Project1
  //                   </a>
  //                 </li>
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link active fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project2_Tab"
  //                     role="tab"
  //                   >
  //                     Project2
  //                   </a>
  //                 </li>
  //                 <li className="nav-item">
  //                   <a
  //                     className="nav-link fw-semibold pt-0"
  //                     data-bs-toggle="tab"
  //                     href="#Project3_Tab"
  //                     role="tab"
  //                   >
  //                     Project3
  //                   </a>
  //                 </li>
  //               </ul>
  //             </div>

  //             <div className="card-body pt-0">

  //               <div className="tab-content">
  //                 <div
  //                   className="tab-pane"
  //                   id="Project1_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-3.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Payment App
  //                           </h4>
  //                           <p className="text-muted mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold">
  //                               <i className="far fa-fw fa-clock" /> 35 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end  mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           02 June 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold  mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           31 Oct 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Design</span>
  //                       </li>
  //                       <li className="complete continuous">
  //                         <span>Development</span>
  //                       </li>
  //                       <li >
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-circle text-success" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       34% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-warning"
  //                         role="progressbar"
  //                         style={{
  //                           width: "34%"
  //                         }}
  //                         aria-valuenow={34}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />6
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               34/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //                 <div
  //                   className="tab-pane active"
  //                   id="Project2_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-2.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Banking
  //                           </h4>
  //                           <p className="text-muted  mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold ">
  //                               <i className="far fa-fw fa-clock" /> 35 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           15 Nov 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           28 Fab 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete continuous">
  //                         <span>Design</span>
  //                       </li>
  //                       <li >
  //                         <span>Development</span>
  //                       </li>
  //                       <li >
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-circle text-success" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       15% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-purple"
  //                         role="progressbar"
  //                         style={{
  //                           width: "15%"
  //                         }}
  //                         aria-valuenow={15}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />4
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               15/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //                 <div
  //                   className="tab-pane"
  //                   id="Project3_Tab"
  //                   role="tabpanel"
  //                 >
  //                   <div className="row">
  //                     <div className="col-md-6">
  //                       <div className="media mb-3">
  //                         <img
  //                           src="assets/images/small/project-1.jpg"
  //                           alt="test"
  //                           className="thumb-md rounded-circle"
  //                         />
  //                         <div className="media-body align-self-center text-truncate ms-3">
  //                           <h4 className="m-0 fw-semibold text-dark font-16">
  //                             Transfer Money
  //                           </h4>
  //                           <p className="text-muted  mb-0 font-13">
  //                             <span className="badge badge-soft-pink fw-semibold">
  //                               <i className="far fa-fw fa-clock" /> 2 days
  //                               left
  //                             </span>
  //                           </p>
  //                         </div>

  //                       </div>
  //                     </div>

  //                     <div className="col-md-6 text-lg-end  mb-2 mb-lg-0">
  //                       <h6 className="fw-semibold m-0">
  //                         Start :{" "}
  //                         <span className="text-muted fw-normal">
  //                           01 Jan 2021
  //                         </span>
  //                       </h6>
  //                       <h6 className="fw-semibold mb-0 mt-2">
  //                         Deadline :{" "}
  //                         <span className="text-muted fw-normal">
  //                           {" "}
  //                           15 Mar 2021
  //                         </span>
  //                       </h6>
  //                     </div>

  //                   </div>

  //                   <div className="holder">
  //                     <ul className="steppedprogress pt-1">
  //                       <li className="complete">
  //                         <span>Planing</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Design</span>
  //                       </li>
  //                       <li className="complete">
  //                         <span>Development</span>
  //                       </li>
  //                       <li className="complete finish">
  //                         <span>Testing</span>
  //                       </li>
  //                     </ul>
  //                   </div>
  //                   <div className="task-box">
  //                     <div className="task-priority-icon mb-3">
  //                       <i className="fas fa-check text-danger" />
  //                     </div>
  //                     <p className="text-muted mb-1">
  //                       There are many variations of passages of Lorem Ipsum
  //                       available, but the majority have suffered alteration
  //                       in some form.
  //                     </p>
  //                     <p className="text-muted text-end mb-1">
  //                       100% Complete
  //                     </p>
  //                     <div
  //                       className="progress mb-3"
  //                       style={{
  //                         height: "4px"
  //                       }}
  //                     >
  //                       <div
  //                         className="progress-bar bg-danger"
  //                         role="progressbar"
  //                         style={{
  //                           width: "100%"
  //                         }}
  //                         aria-valuenow={100}
  //                         aria-valuemin={0}
  //                         aria-valuemax={100}
  //                       />
  //                     </div>
  //                     <div className="d-flex justify-content-between">
  //                       <div className="img-group">
  //                         <a className="user-avatar" href="/">
  //                           <img
  //                             src="assets/images/users/user-8.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-5.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-4.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a className="user-avatar ms-n3" href="/">
  //                           <img
  //                             src="assets/images/users/user-6.jpg"
  //                             alt="user"
  //                             className="thumb-xs rounded-circle"
  //                           />
  //                         </a>
  //                         <a
  //                           href="/"
  //                           className="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm"
  //                         >
  //                           <i className="las la-plus" />2
  //                         </a>
  //                       </div>

  //                       <ul className="list-inline mb-0 align-self-center">
  //                         <li className="list-item d-inline-block me-2">
  //                           <a href="/">
  //                             <i className="mdi mdi-format-list-bulleted text-success font-15" />
  //                             <span className="text-muted fw-bold">
  //                               100/100
  //                             </span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-comment-outline text-primary font-15" />
  //                             <span className="text-muted fw-bold">3</span>
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a className="ms-2" href="/">
  //                             <i className="mdi mdi-pencil-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                         <li className="list-item d-inline-block">
  //                           <a href="/">
  //                             <i className="mdi mdi-trash-can-outline text-muted font-18" />
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>

  //                 </div>

  //               </div>
  //             </div>

  //           </div>

  //         </div>
  //       </div>
  //       <div className="row">
  //         <div className="col-lg-8">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Assigned Tasks</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <a href="/" className="text-primary">
  //                     View All
  //                   </a>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="table-responsive">
  //                 <table className="table table-hover mb-0">
  //                   <thead className="thead-light">
  //                     <tr>
  //                       <th>Task Name</th>
  //                       <th>Name</th>
  //                       <th>Start Date</th>
  //                       <th>Deadline</th>
  //                       <th>Status</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>Product Devlopment</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-2.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Kevin J. Heal
  //                       </td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>New Office Building</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-3.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Frank M. Lyons
  //                       </td>
  //                       <td>11/6/2021</td>
  //                       <td>15/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-4.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Hyman M. Cross
  //                       </td>
  //                       <td>21/6/2021</td>
  //                       <td>3/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Market Research</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-5.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Angelo E. Butler
  //                       </td>
  //                       <td>30/4/2021</td>
  //                       <td>1/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Export Marketing</td>
  //                       <td>
  //                         <img
  //                           src="assets/images/users/user-6.jpg"
  //                           alt="test"
  //                           className="thumb-sm rounded me-2"
  //                         />
  //                         Robert C. Golding
  //                       </td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //         <div className="col-lg-4">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Tasks Performance</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <div className="dropdown">
  //                     <a
  //                       href="#"
  //                       className="btn btn-sm btn-outline-light dropdown-toggle"
  //                       data-bs-toggle="dropdown"
  //                       aria-haspopup="true"
  //                       aria-expanded="false"
  //                     >
  //                       <i className="mdi mdi-dots-horizontal text-muted" />
  //                     </a>
  //                     <div className="dropdown-menu dropdown-menu-end">
  //                       <a className="dropdown-item" href="/">
  //                         Purchases
  //                       </a>
  //                       <a className="dropdown-item" href="/">
  //                         Emails
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="text-center">
  //                 <div id="task_status" className="apex-charts" />
  //                 <h6 className="text-primary bg-soft-primary p-3 mb-0">
  //                   <i
  //                     data-feather="calendar"
  //                     className="align-self-center icon-xs me-1"
  //                   />
  //                   01 January 2021 to 31 June 2021
  //                 </h6>
  //               </div>
  //             </div>

  //           </div>

  //         </div>

  //       </div>

  //       <div className="row">
  //         <div className="col-lg-4">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">Activity</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <div className="dropdown">
  //                     <a
  //                       href="#"
  //                       className="btn btn-sm btn-outline-light dropdown-toggle"
  //                       data-bs-toggle="dropdown"
  //                       aria-haspopup="true"
  //                       aria-expanded="false"
  //                     >
  //                       All
  //                       <i className="las la-angle-down ms-1" />
  //                     </a>
  //                     <div className="dropdown-menu dropdown-menu-end">
  //                       <a className="dropdown-item" href="/">
  //                         Purchases
  //                       </a>
  //                       <a className="dropdown-item" href="/">
  //                         Emails
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body p-0">
  //               <div
  //                 className="p-3"
  //                 style={{
  //                   height: "420px"
  //                 }}
  //                 data-simplebar
  //               >
  //                 <div className="activity">
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="las la-user-clock bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Donald</span>
  //                           updated the status of <a href="/">
  //                             Refund #1234
  //                           </a>{" "}
  //                           to awaiting customer response
  //                         </p>
  //                         <small className="text-muted">10 Min ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-timer-off bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Lucy Peterson</span>
  //                           was added to the group, group name is{" "}
  //                           <a href="/">Overtake</a>
  //                         </p>
  //                         <small className="text-muted">50 Min ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <img
  //                         src="assets/images/users/user-5.jpg"
  //                         alt="test"
  //                         className="rounded-circle thumb-sm"
  //                       />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Joseph Rust</span>
  //                           opened new showcase <a href="/">
  //                             Mannat #112233
  //                           </a>{" "}
  //                           with theme market
  //                         </p>
  //                         <small className="text-muted">10 hours ago</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-clock-outline bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Donald</span>
  //                           updated the status of <a href="/">
  //                             Refund #1234
  //                           </a>{" "}
  //                           to awaiting customer response
  //                         </p>
  //                         <small className="text-muted">Yesterday</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <i className="mdi mdi-alert-outline bg-soft-primary" />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Lucy Peterson</span>
  //                           was added to the group, group name is{" "}
  //                           <a href="/">Overtake</a>
  //                         </p>
  //                         <small className="text-muted">14 Nov 2021</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="activity-info">
  //                     <div className="icon-info-activity">
  //                       <img
  //                         src="assets/images/users/user-4.jpg"
  //                         alt="test"
  //                         className="rounded-circle thumb-sm"
  //                       />
  //                     </div>
  //                     <div className="activity-info-text">
  //                       <div className="d-flex justify-content-between align-items-center">
  //                         <p className="text-muted mb-0 font-13 w-75">
  //                           <span>Joseph Rust</span>
  //                           opened new showcase <a href="/">
  //                             Mannat #112233
  //                           </a>{" "}
  //                           with theme market
  //                         </p>
  //                         <small className="text-muted">15 Nov 2021</small>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>

  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //         <div className="col-lg-8">
  //           <div className="card">
  //             <div className="card-header">
  //               <div className="row align-items-center">
  //                 <div className="col">
  //                   <h4 className="card-title">All Projects</h4>
  //                 </div>

  //                 <div className="col-auto">
  //                   <a href="/" className="text-primary">
  //                     View All
  //                   </a>
  //                 </div>

  //               </div>

  //             </div>

  //             <div className="card-body">
  //               <div className="table-responsive">
  //                 <table className="table table-hover mb-0">
  //                   <thead className="thead-light">
  //                     <tr>
  //                       <th>Project Name</th>
  //                       <th>Start Date</th>
  //                       <th>Deadline</th>
  //                       <th>Status</th>
  //                       <th>Progress</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>Product Devlopment</td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           92%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "92%"
  //                             }}
  //                             aria-valuenow={92}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>New Office Building</td>
  //                       <td>11/6/2021</td>
  //                       <td>15/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           0%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "0%"
  //                             }}
  //                             aria-valuenow={0}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>21/6/2021</td>
  //                       <td>3/7/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-warning">
  //                           Panding
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           0%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "0%"
  //                             }}
  //                             aria-valuenow={0}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>

  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Market Research</td>
  //                       <td>30/4/2021</td>
  //                       <td>1/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           78%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}

  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "78%"
  //                             }}
  //                             aria-valuenow={78}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Export Marketing</td>
  //                       <td>20/3/2021</td>
  //                       <td>5/5/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-success">
  //                           Active
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           45%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-success"
  //                             role="progressbar"
  //                             style={{
  //                               width: "45%"
  //                             }}
  //                             aria-valuenow={45}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Website & Blog</td>
  //                       <td>8/4/2021</td>
  //                       <td>2/6/2021</td>
  //                       <td>
  //                         <span className="badge badge-md badge-boxed  badge-soft-danger">
  //                           Complete
  //                         </span>
  //                       </td>
  //                       <td>
  //                         <small className="float-end ms-2 pt-1 font-10">
  //                           100%
  //                         </small>
  //                         <div
  //                           className="progress mt-2"
  //                           style={{
  //                             height: "3px"
  //                           }}
  //                         >
  //                           <div
  //                             className="progress-bar  bg-pink"
  //                             role="progressbar"
  //                             style={{
  //                               width: "100%"
  //                             }}
  //                             aria-valuenow={100}
  //                             aria-valuemin={0}
  //                             aria-valuemax={100}
  //                           />
  //                         </div>

  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>

  //             </div>

  //           </div>

  //         </div>

  //       </div>
  //   </div>
  //   </>
  // );
