import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;
const Login_url  = process.env.REACT_APP_LOGINURL;


const login = (username, password) => {
  var payloadData = {
    functionName: "LoginCustomer",
    postData: {
      "email": username,
      "password": password
    },
  }
  return axios.post(Login_url + "custlogin", payloadData)
    .then((response) => {
      if (response.data.status) {
        // if (response.data.accessToken) {
        var user_data = response.data.userData;
        user_data['username'] = user_data.email;
        user_data['accessToken'] = user_data._id;
        user_data['session-id'] = response.data.sessionID;
        // user_data['roles'] = [
        //   "ROLE_ADMIN",
        //   "ROLE_USER"
        // ];
        console.log("Login Response:-", response)
        localStorage.setItem("user", JSON.stringify(user_data));
        localStorage.setItem("session-id", response.data.sessionID);
      }
      // return response.data;
      console.log("User Data:-", user_data);
      return user_data;
    }
  );
};

const register = (payload) => {
  var payloadData = {
    functionName: "RegisterCustomer",
    postData: {
      "email": payload.email,
      "password": payload.password,
      "country": payload.country,
      "salutation": payload.salutation,
      "first_name": payload.first_name,
      "last_name": payload.last_name,
      "house_no": payload.house_no,
      "build_street": payload.build_street,
      "city": payload.city,
      "state": payload.state,
      "zip_code": payload.zip_code,
      "phone": payload.phone,
      "birth_date": payload.birth_date,
      "mar_status": payload.mar_status,
    },
  }
  return axios.post(Login_url + "custreg", payloadData)
    .then((response) => {
      return(response);
    }
  );
};

const AuthcustService = {
  login,
  register
};
  
export default AuthcustService;
  