import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import {Card, CardBody, CardTitle } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import RoleService from "./roles.service";
import CommonService from "../common.service";
import $ from 'jquery'; 



const UpdateRole = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [modulesList, setModules] = useState("");
  const [functionsList, setFunctions] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { state } = location;
  const [role_id, setRoleid] = useState("");

  const form = useRef();
  const checkBtn = useRef();
  
  const [rolename, setRolename] = useState("");
  const [landingpage, setlandingpage] = useState("");
  
  const [functionvalues, setFunctvalues] = useState(state.role_functions);
  var b_arr = state.role_modules;
  var b_col = b_arr.map(x => x.module_id); 
  const [modulesvalues, setModvalues] = useState(b_col);
  const [Selected_modules, setSelected_modules] = useState(b_col);
  
  
  
  
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeRolename = (e) => {
    const rolename = e.target.value;
    setRolename(rolename);
  };
  const onChangelandingpage = (e) => {
    const landingpage = e.target.value;
    setlandingpage(landingpage);
  };
  

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
    fetchModules();
    fetchFunctions();

  }, []);

  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          {t('This field is required!')}
        </div>
      );
    }
  };
  
  const vrolename = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="invalid-feedback d-block">
        {t('The rolename must be between 3 and 20 characters.')}
        </div>
      );
    }
  };

  const fetchModules = async () => {
    try {
      var pdata = {
        status:1
      }
      const response = await CommonService.fetchModuleswithfunctions(pdata);
      const data = await response.data.data;
      
        for(var i=0;i < data.length;i++){
          if(state){
            data[i].is_assigned = await inArray(data[i]._id,Selected_modules);
          }else{
            data[i].is_assigned = false;
          }
        }
        
      setModules(data);
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchFunctions = async () => {
      try {
        const response = await CommonService.fetchFunctions();
        const data = await response.data.data;
        for(var i=0;i < data.length;i++){
          if(state){
            data[i].is_assigned = await inArray(data[i]._id,functionvalues);
          }else{
            data[i].is_assigned = false;
          }
        }
        
        setFunctions(data);
      } catch (error) {
        console.log("error", error);
      }
    };
  
  function setData() {
    if(state) {
      console.log("Setting Editable values")
      setRolename(state.name);
      setRoleid(state._id)
      setlandingpage(state.landingpage);
      
    }
  }

  function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
  }

  const handleFunctionChange = (e) => {
    if(e.target.checked){
      functionvalues.push(e.target.value);
    }
    else {
      var darr = functionvalues;
      const index = darr.indexOf(e.target.value);
      if (index > -1) { // only splice array when item is found
        darr.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFunctvalues(darr);
    }
  };

  const handleModuleChange = (e) => {
    const modval = e.target.value;
    if($(e.target).prop( "checked" ))
    $(".child-check-box-"+modval).prop( "checked", true );
    else
    $(".child-check-box-"+modval).prop( "checked", false );
    modulesvalues.push(modval);
  };

  $('body').on("click",".toggle-btn",function(){
  
    var element = document.getElementById($(this).attr('aria-controls'));
    if(element.classList.contains("show")){
      const myTimeout = setTimeout(function(){
        element.classList.remove("show");
      }, 50);
      
    }else{
      const myTimeout = setTimeout(function(){
        element.classList.add("show");
      }, 50);
    }
    
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);
    form.current.validateAll();
    var Modules = [];
    var Functions = [];
    $('input.module-checkbox:checkbox:checked').each(function () {

      var sThisVal = $(this).val();
      var arr = {};
      arr['module_id'] = sThisVal;
      $('input.child-check-box-'+sThisVal).each(function () {
        if($(this).prop( "checked" )){
          arr[$(this).attr('data-label')] = 1;
          Functions.push($(this).val());
        }else{
          arr[$(this).attr('data-label')] = 0;
        }
      });
      Modules.push(arr);
    });
    if (checkBtn.current.context._errors.length === 0) {
      var payloadData = {
        functionName: "UpdateUserRole",
        postData: {
          name: rolename,
          role_modules: Modules,
          role_functions: Functions,
          landingpage:$("#landingpage").val(),
          _id: role_id
        },
      }
      setLoading(true);
      console.log("Update Role payload:-", payloadData)
      RoleService.updateRole(payloadData).then((response) => {        
        console.log("API Response:-", response);
        setLoading(false);
        if(response.data.status){
          navigate("/roles-list")
          if(response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message)
          }
          else {
            Alerts.swalSuccessAlert(t("Role updated successfully."));
          }
        }
        else {
          if(response.data.message !== '') {
            Alerts.swalErrorAlert(response.data.message)
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't update role at the moment. Please try again later."))
          }
        }
      },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
    }
  };

  return (
    <>
      <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper justify-content-center">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="pe-7s-pen icon-gradient bg-happy-green" />
                  </div>
                  <div>{t('Update Role')}
                    <div className="page-title-subheading">
                    {t('Edit the form below to update a Role.')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="d-flex justify-content-center">
              <div className="col-md-12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles = {{overlay: (base) => ({...base})}}
                      spinner = {<DNALoader/>}>  
                      <Form onSubmit={handleSubmit} ref={form}>
                        {!successful && (
                          <div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6 col-xl-6" >
                                  <label htmlFor="username">{t('Name')}</label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="rolename"
                                    placeholder={t('Role Name')}
                                    value={rolename}
                                    onChange={onChangeRolename}
                                    validations={[required, vrolename]}
                                    />
                                </div>
                                <div className="col-md-6 col-xl-6" >
                                  <label htmlFor="username" className="mb-2">Select Landing</label>
                                  <select className="form-select" id="landingpage" onChange={onChangelandingpage}  value={landingpage}>
                                    <option disabled={true} value="">--Choose and option--</option>
                                    {modulesList && modulesList.map((module, index) => (
                                      <option value={module.route}>{module.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <br/>
                          </div>
                        )}
                        {modulesList &&
                        <Card body className="border-0 shadow-none mb-3 px-0">
                          <div className="row">
                            <h5 className="mb-3">{t('Assign Modules')}</h5>
                            {modulesList && modulesList.map((module, index) => (
                              <div className="accordion col-md-6 col-xl-6 pb-3" id={"accordion"+module._id}>
                                <div className="card">
                                  <div className="card-header bg-light" id={"heading"+module._id}>
                                    
                                      <input className="form-check-input module-checkbox"
                                        type="checkbox" 
                                        name="modules[]"
                                        value={module._id}
                                        onChange={handleModuleChange}
                                        defaultChecked={module.is_assigned}
                                      />
                                      {' '}
                                      <button className="btn btn-link toggle-btn mt-1" type="button" data-toggle="collapse" data-target={"#collapse"+module._id} aria-expanded="true" aria-controls={"collapse"+module._id} >
                                      {module.name}
                                      </button>
                                    {/* </h5> */}
                                  </div>

                                  <div id={"collapse"+module._id}  className="collapse-div collapse" aria-labelledby={"heading"+module._id} data-parent={"accordion"+module._id}>
                                    <div className="card-body ms-4 pt-1" >
                                      
                                      {functionsList &&
                                        functionsList.map((func, index2) => {
                                          if(func.module_id === module._id) { 
                                            return (
                                              <div className="form-check mt-3">
                                                <input
                                                  className={"form-check-input child-check-box-"+module._id}
                                                  type="checkbox"
                                                  data-label={func.name}
                                                  value={func._id}
                                                  defaultChecked={func.is_assigned}
                                                  onChange={handleFunctionChange}
                                                />
                                                <label className="form-check-label" htmlFor="users">
                                                  {func.name}
                                                </label>
                                              </div>
                                            )
                                          }
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            
                          </div>


                        </Card>
                        }
                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                          <center>
                              <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                              <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Update Role')}</button>
                            </center>
                          </div>
                        </div>
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
    </>
    
  );

};

export default UpdateRole;


 